import { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { StyledDivider } from '@/components/common/divider'
import { HomeSectionTitle } from '@/components/domains/home'
import { Anchor } from '@/components/domains/home/Anchor'
import { SectionCategoryTab } from '@/components/domains/home/new/section-category-tab'
import { WeeklyBestProductList } from '@/components/domains/home/new/weekly-best-product-list'
import { bestTabNameToLegacySlug } from '@/constants/best-seller-category-type.enum'
import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { HomeSectionTitleEnum } from '@/constants/home-section-title.enum'
import ROUTES from '@/constants/legacy/constRoutes'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { useIntersectionObserver } from '@/containers/hooks'
import type { HomeSectionWeeklyBestResponseDto } from '@/apis/rest/generated/types'

interface HomeSectionWeeklyBestProps {
  homeSectionItem: HomeSectionWeeklyBestResponseDto
  refCallback: (node: HTMLDivElement) => void
  sectionIndex: number
}

export const HomeSectionWeeklyBest = ({ homeSectionItem, refCallback, sectionIndex }: HomeSectionWeeklyBestProps) => {
  if (!homeSectionItem.displayCollectionsWithProducts || !homeSectionItem.displayCollectionsWithProducts.length) {
    return null
  }

  const { color } = useTheme()
  const [selectedId, setSelectedId] = useState<number>(homeSectionItem.displayCollectionsWithProducts[0].id)
  const selectedBest = homeSectionItem.displayCollectionsWithProducts?.find((d) => d.id === selectedId)

  const { triggerCustomEvent } = useTracker()
  const { elementRef } = useIntersectionObserver(
    () => {
      triggerCustomEvent(FitpetMallEventEnum.ViewHomeSection, { sectionName: HomeSectionTitleEnum.WeeklyBest })
    },
    { rootMargin: '0px' }
  )

  const handleTabClick = (id: number) => {
    setSelectedId(id)
  }

  if (!homeSectionItem.displayCollectionsWithProducts) {
    return null
  }

  const initSelectedId = homeSectionItem.displayCollectionsWithProducts[0]?.id
  const selectedList = homeSectionItem.displayCollectionsWithProducts?.find((d) => d.id === selectedId)
  return (
    <>
      <Anchor id={HomeSectionTitleEnum.WeeklyBest} refCallback={refCallback} />
      <StyledContainer ref={elementRef}>
        <HomeSectionTitle
          sectionIndex={sectionIndex}
          seeMoreLink={{
            pathname: ROUTES.BEST_SELLER,
            query: {
              categoryName: bestTabNameToLegacySlug(
                selectedList?.name || homeSectionItem.displayCollectionsWithProducts[0].name
              ),
            },
          }}
          showSeeMoreButton
          hasMarginBottom={false}
        >
          {HomeSectionTitleEnum.WeeklyBest}
        </HomeSectionTitle>
        <SectionCategoryTab
          selectedId={selectedId || initSelectedId}
          categoryTabs={homeSectionItem.displayCollectionsWithProducts}
          onClick={handleTabClick}
        />
        <WeeklyBestProductList
          sectionIndex={sectionIndex}
          sectionName={HomeSectionTitleEnum.WeeklyBest}
          list={selectedBest?.products || []}
        />
      </StyledContainer>
      <StyledDivider height="0.1rem" backgroundColor={color.gray70} />
    </>
  )
}

const StyledContainer = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
