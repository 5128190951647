import { useEffect, useState } from 'react'
import { getDayjs, getDuration } from '@/utils/utilDate'

interface CountdownProps {
  format?: string
  /** 만료 시간 */
  value: Date | number | string | undefined
  /** 타이머 종료 시 호출되는 함수 */
  onFinish: () => void
  /** 스타일 클래스 */
  className?: string
}

const INTERVAL_TIME = 1000
const TIME_PAD_START = 2

/**
 * `antd`의 Countdown을 대체하고자 만든 Countdown UI
 *
 * antd와 동일합니다. 단, 시작시점이 렌더링에 따라서 antd의 Countdown과 조금 다릅니다.
 *
 * @example
 * <StyledCountdown
 *   value={codeSent && userCertData?.expiredAt}
 *   onFinish={() => {
 *     setCodeSent(false)
 *   }}
 * />
 */
export const Countdown = ({ format = 'mm:ss', value, onFinish, className }: CountdownProps) => {
  const [timeLeft, setTimeLeft] = useState<number | null>(value ? getDayjs(value).diff(getDayjs()) : null)

  /**
   * props `value` 변경 시 value값에 맞게 timeLeft 변경하는 사이트디펙트
   */
  useEffect(() => {
    setTimeLeft(value ? getDayjs(value).diff(getDayjs()) : null)
  }, [value])

  /**
   * timeLeft 가지고 -1초씩 감소시키는 인터벌 사이드이펙트
   */
  useEffect(() => {
    if (timeLeft === null) return
    if (timeLeft <= 0) {
      return
    }

    const interval = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        if (prevTimeLeft === null) {
          return null
        }

        const newTimeLeft = prevTimeLeft - INTERVAL_TIME

        if (newTimeLeft <= 0) {
          clearInterval(interval)
          onFinish()
        }

        return newTimeLeft
      })
    }, INTERVAL_TIME)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft])

  return <div className={className}>{timeLeft !== null ? formatTimeLeft(timeLeft, format) : '00:00'}</div>
}

const formatTimeLeft = (milliseconds: number, format: string) => {
  if (milliseconds < 0) milliseconds = 0

  const duration = getDuration(milliseconds)
  const totalDays = Math.floor(duration.asDays())
  const hours = duration.hours()
  const minutes = duration.minutes()
  const seconds = duration.seconds()

  return format
    .replace('DD', String(totalDays).padStart(TIME_PAD_START, '0'))
    .replace('D', String(totalDays))
    .replace('HH', String(hours).padStart(TIME_PAD_START, '0'))
    .replace('H', String(hours))
    .replace('mm', String(minutes).padStart(TIME_PAD_START, '0'))
    .replace('m', String(minutes))
    .replace('ss', String(seconds).padStart(TIME_PAD_START, '0'))
    .replace('s', String(seconds))
}
