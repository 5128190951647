import { useCallback } from 'react'
import { ProductScheme } from '@/apis/rest/generated/types'
import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { LocationEnum } from '@/constants/location.enum'
import { RecommendProductListTypeEnum } from '@/constants/recommend-product-list-type.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { ProductTypeDefinedByFrontend } from '@/types/product-type-defined-by.frontend'
import { convertHomeProductCardParams } from '@/utils/event-tracker/convertParams/convertProductParams'
import { GraphQLProductType } from '@/utils/product/product-card.util'
import { ReferrerUtil } from '@/utils/referrer.util'
import { decodeIdForEventTracker } from '@/utils/utilApi'

interface TrackerProperties {
  search_engine?: string
  search_query?: string
  ab_test_group?: string
}

interface TrackClickHomeSectionMoreProps {
  sectionIndex?: number
  sectionName: string
}

interface TrackClickHomeSectionProductProps {
  product: GraphQLProductType
  sectionIndex?: number
  sectionName: string
}

interface TrackClickHomeBrandSectionProductProps {
  product: ProductScheme
  sectionIndex?: number
  sectionName: string
}

interface TrackClickHomeBestSectionProductProps {
  product: ProductScheme
  sectionIndex?: number
  sectionName: string
}

interface TrackClickHomeBrandShipProductProps {
  brandId?: number
  brandName?: string
  type: string
}

interface TrackViewFrequentlyListProps {
  listType: RecommendProductListTypeEnum
  location: LocationEnum
}

interface TrackClickHomeDisplayProductProps {
  product: GraphQLProductType
  displayItemId?: string
  displayItemName?: string
}

interface TrackClickHomeBrandDisplayProductProps {
  product: ProductScheme
  displayItemId?: string
  displayItemName?: string
}

interface TrackRecommendSectionProps {
  sectionName: string
  product: ProductScheme
}

const useHomeTracker = () => {
  const tracker = useTracker()
  const referrer = ReferrerUtil.getReferrer()

  const trackViewHomeEvent = useCallback(
    (ab?: string) => {
      const referrerDomain = ReferrerUtil.getReferrerDomain(referrer)
      const searchQuery = ReferrerUtil.getSearchQuery(referrer)

      const trackerProperties: TrackerProperties = {}
      if (ab) {
        trackerProperties['ab_test_group'] = `home_brand_${ab.toLowerCase()}`
      }
      if (referrerDomain) {
        trackerProperties['search_engine'] = referrerDomain
      }
      if (searchQuery) {
        trackerProperties['search_query'] = searchQuery
      }
      tracker.triggerCustomEvent(FitpetMallEventEnum.ViewHome, trackerProperties)
    },
    [tracker, referrer]
  )

  const trackClickHomeSectionMoreEvent = ({ sectionIndex, sectionName }: TrackClickHomeSectionMoreProps) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickHomeSectionMore, {
      section_index: sectionIndex,
      section_name: sectionName,
    })
  }

  const trackClickHomeSectionProductEvent = ({
    product,
    sectionName,
    sectionIndex,
  }: TrackClickHomeSectionProductProps) => {
    const properties = {
      productId: decodeIdForEventTracker(product.id) || '',
      productName: product.name,
      productPrice: product.price,
      productPurchasePrice: product.customerPrice,
      productDiscountRate: product.discountRate,
      sectionName,
      sectionIndex,
      brandId: decodeIdForEventTracker(product.brand?.id),
      brandName: product.brand?.name,
      supplyType: product.supplyType,
    }

    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickHomeSectionProduct, properties)
  }

  /**
   * @deprecated
   * @use trackClickHomeBrandSectionProductV2Event
   */
  const trackClickHomeBrandSectionProductEvent = ({
    product,
    sectionName,
    sectionIndex,
  }: TrackClickHomeBrandSectionProductProps) => {
    const properties = {
      productId: product.id,
      productName: product.name,
      productPrice: product.price,
      productPurchasePrice: product.defaultOption.salePrice,
      productDiscountRate: product.discountRate,
      sectionName,
      sectionIndex,
      brandId: product.brand.id,
      brandName: product.brand.name,
      supplyType: product.supplyType,
    }

    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickHomeSectionProduct, properties)
  }

  const trackClickHomeBestSectionProductEvent = ({
    product,
    sectionName,
    sectionIndex,
  }: TrackClickHomeBestSectionProductProps) => {
    const properties = {
      productId: product.id,
      productName: product.name,
      productPrice: product.price,
      productPurchasePrice: product.defaultOption.salePrice,
      productDiscountRate: product.discountRate,
      sectionName,
      sectionIndex,
      brandId: product.brand?.id,
      brandName: product.brand?.name,
      supplyType: product.supplyType,
    }

    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickHomeSectionProduct, properties)
  }

  const trackClickHomeWeeklyBestProductEvent = (product: ProductScheme) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickHomeWeeklybestProduct, product)
  }

  const trackClickHomeNewProductEvent = (product: GraphQLProductType) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickHomeNewProduct, convertHomeProductCardParams(product))
  }

  const trackViewHomeSectionEvent = (sectionName: string) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ViewHomeSection, {
      sectionName: sectionName,
    })
  }

  const trackClickHomeDisplayProductEvent = ({
    product,
    displayItemId,
    displayItemName,
  }: TrackClickHomeDisplayProductProps) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickHomeDisplayProduct, {
      ...product,
      displayItemId: displayItemId || product.id,
      displayItemName: displayItemName || product.name,
    })
  }

  const trackClickHomeBrandDisplayProductEvent = ({
    product,
    displayItemId,
    displayItemName,
  }: TrackClickHomeBrandDisplayProductProps) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickHomeDisplayProduct, {
      ...product,
      displayItemId: displayItemId || product.id,
      displayItemName: displayItemName || product.name,
    })
  }

  const trackClickHomeBrandShopItemEvent = ({ brandId, brandName, type }: TrackClickHomeBrandShipProductProps) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickHomeBrandshopItem, {
      brandId,
      brandName,
      type,
    })
  }

  const trackViewFrequentlyListEvent = ({ listType, location }: TrackViewFrequentlyListProps) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ViewFrequentlyList, {
      listType,
      location,
    })
  }

  const trackClickHomeSpecialSaleProductEvent = (product: GraphQLProductType) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickHomeSpecialsaleProduct, convertHomeProductCardParams(product))
  }

  const trackClickHomeNudgeMessageEvent = () => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickHomeNudgeMessage, {
      messageText: '이 상품 필요하세요?',
    })
  }

  const trackViewHomeRecommendSectionEvent = ({
    sectionName,
    listType,
  }: {
    sectionName: string
    listType: 'recent_recommend' | 'frequent_recommend' | 'personalize_recommend' | 'awsrecipe_recommend'
  }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ViewHomeRecommendSection, {
      sectionName: sectionName,
      list_type: listType,
    })
  }

  const trackClickHomeRecommendSectionMoreEvent = ({
    sectionName,
    listType,
  }: {
    sectionName: string
    listType: 'recent_recommend' | 'frequent_recommend' | 'personalize_recommend' | 'awsrecipe_recommend'
  }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickHomeRecommendSectionMore, {
      sectionName: sectionName,
      list_type: listType,
    })
  }

  /**
   * @deprecated
   * */
  const trackClickHomeRecommendProductEvent = ({
    sectionName,
    product,
    addLocation,
    listType,
  }: {
    sectionName: string
    // product?: ProductScheme
    product: {
      id: number
      name: string
      price: number
      salePrice: number
      discountRate: number
      reviewScore: number
      reviewCount: number
    }
    addLocation?: 'home_section' | 'more_list'
    listType?: 'recent_recommend' | 'frequent_recommend' | 'personalize_recommend' | 'awsrecipe_recommend'
  }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickHomeRecommendProduct, {
      sectionName: sectionName,
      productId: product.id,
      productName: product.name,
      productPrice: product.price,
      productPurchasePrice: product.salePrice,
      productDiscountRate: product.discountRate,
      productReviewAvgscore: product.reviewScore,
      productTotalReviewCount: product.reviewCount,
      add_location: addLocation,
      list_type: listType,
    })
  }

  const trackClickHomeRecommendProductV2Event = ({
    sectionName,
    product,
    location,
    addLocation,
    listType,
  }: {
    sectionName: string
    product: ProductTypeDefinedByFrontend
    location?: string
    addLocation?: 'home_section' | 'more_list'
    listType?: 'recent_recommend' | 'frequent_recommend' | 'personalize_recommend' | 'awsrecipe_recommend'
  }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickHomeRecommendProduct, {
      sectionName: sectionName,
      productId: product.id,
      productName: product.name,
      productPrice: product.price,
      productPurchasePrice: product.salePrice,
      productDiscountRate: product.discountRate,
      productReviewAvgscore: product.reviewScore,
      productTotalReviewCount: product.reviewCount,
      add_location: addLocation,
      is_shownprice_coupondiscounted: product?.hasApplicableProductCoupon,
      list_type: listType,
      location: location,
    })
  }

  const trackClickSectionListProductEvent = ({ sectionName, product }: TrackRecommendSectionProps) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickSectionListProduct, {
      sectionName: sectionName,
      productId: product.id,
      productName: product.name,
      productPrice: product.price,
      productPurchasePrice: product.salePrice,
      productDiscountRate: product.discountRate,
      productReviewAvgscore: product.review.score,
      productTotalReviewCount: product.review.count,
    })
  }

  const trackClickHomeFloatingButtonEvent = () => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickHomeFloatingButton)
  }

  return {
    trackViewHomeEvent,
    trackClickHomeSectionMoreEvent,
    trackClickHomeSectionProductEvent,
    trackClickHomeWeeklyBestProductEvent,
    trackClickHomeNewProductEvent,
    trackViewHomeSectionEvent,
    trackClickHomeDisplayProductEvent,
    trackClickHomeBrandDisplayProductEvent,
    trackClickHomeBrandShopItemEvent,
    trackViewFrequentlyListEvent,
    trackClickHomeSpecialSaleProductEvent,
    trackClickHomeBestSectionProductEvent,
    trackClickHomeBrandSectionProductEvent,
    trackClickHomeNudgeMessageEvent,
    trackViewHomeRecommendSectionEvent,
    trackClickHomeRecommendSectionMoreEvent,
    trackClickHomeRecommendProductEvent,
    trackClickSectionListProductEvent,
    trackClickHomeFloatingButtonEvent,
    trackClickHomeRecommendProductV2Event,
  }
}

export { useHomeTracker }
