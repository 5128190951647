export const HomeSectionLimit = 20

export const sliceArray = <T>(count: number, list?: Array<T>): Array<T> | never[] => {
  if (!list) {
    return []
  }

  if (list.length >= count) {
    return list.slice(0, count)
  }

  return list
}
