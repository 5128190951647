import { useEffect, useRef } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import { useReactSlickBanner } from '@/components/domains/home/new/use-react-slick-banner'
import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { useCustomRouter } from '@/containers/hooks'
import FitpetPlusLogoSimpleSvg from '@/images/fitpet_logo_mono_color.svg'
import { calculateResponsiveSize } from '@/utils/utilCSS'
import type { ImageTypeBannerResponseDto } from '@/apis/rest/generated/types'

interface ImageBannerSliderProps {
  banners?: ImageTypeBannerResponseDto[]
  eventTrackerName: {
    view: FitpetMallEventEnum
    click: FitpetMallEventEnum
  }
  /** 배너 높이를 제한해야 하는 경우 사용 */
  bannerStyles?: {
    height?: number
    logoWidth?: number
  }
  isError?: boolean
}

export const ImageBannerSlider = ({ isError, banners, eventTrackerName, bannerStyles }: ImageBannerSliderProps) => {
  const { push } = useCustomRouter()
  const { triggerCustomEvent } = useTracker()
  const { elementRef, currentBannerIndex, settings } = useReactSlickBanner(eventTrackerName.view, banners)
  const carouselRef = useRef<Slider | null>(null)

  const handleClick = (banner: ImageTypeBannerResponseDto) => {
    push(banner.link)
    triggerCustomEvent(eventTrackerName.click, {})
  }

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        carouselRef.current?.slickPlay()
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  if (isError || !banners)
    return (
      <StyledErrorContainer height={bannerStyles?.height}>
        <StyledFitpetPlusLogoSimpleSvg width={bannerStyles?.logoWidth} />
      </StyledErrorContainer>
    )

  const pagination = {
    isShow: banners.length > 1,
    currentPage: `${currentBannerIndex + 1}/${banners.length}`,
  }

  if (!banners.length) return null

  return (
    <StyledContainer ref={elementRef}>
      {/* @ts-ignore */}
      <Slider {...settings} ref={carouselRef}>
        {banners?.map((banner) => (
          <StyledImg
            key={banner.id}
            alt={banner.imageUrl}
            src={banner.imageUrl}
            height={bannerStyles?.height}
            onClick={() => handleClick(banner)}
          />
        ))}
      </Slider>
      {pagination.isShow && <StyledPagination>{pagination.currentPage}</StyledPagination>}
    </StyledContainer>
  )
}

const StyledFitpetPlusLogoSimpleSvg = styled(FitpetPlusLogoSimpleSvg)<{ width: number | undefined }>`
  width: ${(props) => calculateResponsiveSize(props.width)};
  color: ${(props) => props.theme.color.gray100};
`

const StyledErrorContainer = styled.div<{ height?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${(props) => calculateResponsiveSize(props?.height)};
  background-color: ${(props) => props.theme.color.gray50};
`

const StyledContainer = styled.div`
  position: relative;
`

const StyledPagination = styled.div`
  position: absolute;
  bottom: 0.8rem;
  right: 0.8rem;

  padding: 0.3rem 0.6rem;
  border-radius: 2rem;
  font-size: 1rem;
  line-height: 1.2rem;
  letter-spacing: 0.1em;
  color: ${(props) => props.theme.color.grayWhite};
  background-color: rgba(0, 0, 0, 0.4);
`

const StyledImg = styled.img<{ height?: number }>`
  height: ${(props) => calculateResponsiveSize(props.height)};
  object-fit: cover;
  width: 100%;
  cursor: pointer;
`
