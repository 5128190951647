import styled from 'styled-components'
import { shineLinesCSS } from '@/components/common/skeleton/ShineLinesCSS'

type SkeletonLineProps = {
  height?: string
  width?: string
}

const SkeletonLine = ({ width = '100%', height = '2rem' }: SkeletonLineProps) => {
  return <Line width={width} height={height} />
}

export { SkeletonLine }

const Line = styled.div<{
  width: string
  height: string
}>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  border-radius: 0.5rem;
  ${shineLinesCSS}
`
