import { FC } from 'react'
import styled from 'styled-components'
import SkeletonProductCard from '@/components/domains/products/skeleton/SkeletonProduct/SkeletonProductCard'
import { CardLayoutEnum } from '@/constants/card-layout.enum'
import { ProductSkeletonLengthEnum } from '@/constants/product-skeleton-length.enum'
import { ProductThumbnailSizeEnum } from '@/constants/product-thumbnail-size.enum'

type SkeletonProductProps = {
  length?: ProductSkeletonLengthEnum
  size?: ProductThumbnailSizeEnum
  type?: CardLayoutEnum
}

const SkeletonProduct: FC<SkeletonProductProps> = ({
  length = ProductSkeletonLengthEnum.Four,
  size = ProductThumbnailSizeEnum.Card136,
  type = CardLayoutEnum.Vertical,
}) => {
  return (
    <>
      {Array.from({ length }).map((_, index) => (
        <StyledCardWrapper key={index} size={size}>
          <SkeletonProductCard size={size} type={type} />
        </StyledCardWrapper>
      ))}
    </>
  )
}

const StyledCardWrapper = styled.div<{ size: ProductThumbnailSizeEnum }>`
  margin: ${({ size }) => (size === ProductThumbnailSizeEnum.Fill ? '0.8rem 0 4rem' : 0)};
`

export default SkeletonProduct
