import { useRef } from 'react'
import * as Sentry from '@sentry/nextjs'
import styled, { useTheme } from 'styled-components'
import { useGetRecentlyBought } from '@/apis/rest/generated/hooks'
import { StyledDivider } from '@/components/common/divider'
import { ScrollContainer } from '@/components/domains/etc'
import { HomeSectionTitle } from '@/components/domains/home'
import { Anchor } from '@/components/domains/home/Anchor'
import { NewProductCard } from '@/components/domains/products/new-product-card/new-product-card'
import { PRODUCT_CARD_SIZES } from '@/components/domains/products/new-product-card/new-product-card.type'
import { HomeSectionTypeEnum } from '@/constants/home-section-type.enum'
import ROUTES from '@/constants/legacy/constRoutes'
import { MinCountForScrollContainerEnum } from '@/constants/min-count-for-scroll-container.enum'
import { PageSizeEnum } from '@/constants/page-size.enum'
import { useHomeTracker } from '@/containers/event-tracker/home.event-tracker'
import { useExecuteOnScrollTop } from '@/containers/hooks/useExecuteOnScrollTop'
import { UserContainer } from '@/containers/users/UserContainer'
import { ProductTypeDefinedByFrontend } from '@/types/product-type-defined-by.frontend'
import { transformProductTypeDefinedByFrontend } from '@/utils/product/product-card.util'
import { removeScrollbar } from '@/utils/utilCSS'
import type { HomeSectionAnchorBadgeObj } from '@/components/domains/home/new/home-section-list/hooks/use-home-section-anchor-badge'

interface HomeSectionRecommendRecentlyBoughtProductProps {
  hasBadge: boolean
  sectionIndex: number
  onSectionView: () => void
  setTargetPersonalSection: (item: HomeSectionAnchorBadgeObj) => void
}
const SECTION_TITLE = '최근 구매한 이 상품!'

// 쇼핑 메인 내 상품추천 섹션 로직 고도화(A/B/C/D) A 그룹
export const HomeSectionRecommendRecentlyBoughtProduct = ({
  hasBadge,
  sectionIndex,
  onSectionView,
  setTargetPersonalSection,
}: HomeSectionRecommendRecentlyBoughtProductProps) => {
  const { color } = useTheme()
  const ref = useRef<HTMLDivElement>(null)
  const { me } = UserContainer.useContainer()
  const {
    trackViewHomeRecommendSectionEvent,
    trackClickHomeRecommendProductEvent,
    trackClickHomeRecommendSectionMoreEvent,
  } = useHomeTracker()

  const { data: recentlyBoughtData } = useGetRecentlyBought(
    {
      page: 1,
      size: PageSizeEnum.PageSize20,
    },
    {
      query: {
        enabled: !!me,
        onSuccess: (res) => {
          if (!!res?.data?.length) {
            setTargetPersonalSection({ anchorId: HomeSectionTypeEnum.RecommendRecentlyBoughtProduct })
            trackViewHomeRecommendSectionEvent({ sectionName: SECTION_TITLE, listType: 'recent_recommend' })
          }
        },
        onError: (error) => {
          Sentry.captureMessage('[홈] 최근 구매 상품 데이터 요청 실패')
          Sentry.captureException(error)
        },
      },
    }
  )

  const url = {
    pathname: ROUTES.SECTION_RECENTLY_BOUGHT,
  }

  useExecuteOnScrollTop({ callback: onSectionView, ref, enabled: hasBadge })

  const handleClickSeeMore = () => {
    trackClickHomeRecommendSectionMoreEvent({
      sectionName: SECTION_TITLE,
      listType: 'recent_recommend',
    })
  }

  const handleClickProduct = (product: ProductTypeDefinedByFrontend) => {
    trackClickHomeRecommendProductEvent({
      sectionName: SECTION_TITLE,
      product: {
        id: product.id,
        name: product.name,
        price: product.price,
        salePrice: product.salePrice,
        discountRate: product.discountRate || 0,
        reviewScore: product.reviewScore,
        reviewCount: product.reviewCount,
      },
      addLocation: 'home_section',
      listType: 'recent_recommend',
    })
  }

  if (!recentlyBoughtData?.data.length) {
    return null
  }

  return (
    <>
      <StyledContainer data-testid={HomeSectionTypeEnum.RecommendRecentlyBoughtProduct} ref={ref}>
        <Anchor id={HomeSectionTypeEnum.RecommendRecentlyBoughtProduct} />
        <HomeSectionTitle
          onClickSeeMore={handleClickSeeMore}
          showSeeMoreButton
          seeMoreLink={url}
          sectionIndex={sectionIndex}
        >
          {SECTION_TITLE}
        </HomeSectionTitle>
        <ScrollContainer
          hideRightButton={recentlyBoughtData?.data?.length < MinCountForScrollContainerEnum.MainHomeSectionProduct}
        >
          <ListContainer>
            {recentlyBoughtData?.data.map(({ product, boughtCount }) => {
              const productData = transformProductTypeDefinedByFrontend(product)

              return (
                <NewProductCard
                  key={product.id}
                  product={productData}
                  size={PRODUCT_CARD_SIZES.lg}
                  onClickProductCard={() => handleClickProduct(productData)}
                  label={{
                    value: `${boughtCount}회 구매`,
                    backgroundColor: color.blue500,
                    variant: 'Body5 Bold',
                  }}
                />
              )
            })}
          </ListContainer>
        </ScrollContainer>
      </StyledContainer>
      <StyledDivider height="0.1rem" backgroundColor={color.gray70} />
    </>
  )
}

const StyledContainer = styled.div`
  overflow: hidden;
  position: relative;
`
const ListContainer = styled.div`
  ${removeScrollbar};
  display: flex;
  gap: 0.8rem;

  & > *:first-child {
    margin-left: 16px;
  }

  & > *:last-child {
    padding-right: 16px;
  }
`
