import { useRef, useState, useCallback, useEffect } from 'react'
import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { BannerWithTracker } from '@/types/banner.type'
import { useTracker } from '../contexts/EventTrackingProvider'

const defaultBannerIndex = 0
type TrackingBannersRef = {
  /** 트래킹한 배너 리스트 */
  trackedBanners: Record<number, boolean>
  /** 배너가 현재 화면에 보이고 있는지 여부 */
  isOnView: boolean
}

interface UseTextSliderBanner<T> {
  banners?: T[]
  viewEventName?: FitpetMallEventEnum
  pagingTime?: number
}

/**
 * react-slick을 사용하지 않은 배너에서 사용
 */
const useTextSliderBanner = <T extends BannerWithTracker>({
  banners,
  viewEventName,
  pagingTime,
}: UseTextSliderBanner<T>) => {
  const { triggerCustomEvent } = useTracker()
  const observerRef = useRef<IntersectionObserver | null>(null)
  const trackingBannersRef = useRef<TrackingBannersRef>({ trackedBanners: {}, isOnView: false })

  const [currentBannerIndex, setCurrentBannerIndex] = useState(defaultBannerIndex)
  const bannerLength = banners?.length ?? 0
  const paginationTime = pagingTime || 5000

  const triggerTracker = useCallback(
    (idx: number) => {
      /** 한번 View 이벤트를 트래킹한 배너는 다시 트래킹하지 않음 */
      if (banners?.length && !trackingBannersRef.current.trackedBanners[idx]) {
        const trackerProperties = banners[idx]?.trackerProperties
        triggerCustomEvent(viewEventName, {
          ...trackerProperties,
        })
        trackingBannersRef.current.trackedBanners[idx] = true
      }
    },
    [banners, triggerCustomEvent, viewEventName]
  )

  const elementRef = useCallback(
    (node: HTMLDivElement) => {
      observerRef.current = new IntersectionObserver(
        (entries) => {
          /** viewport에 보이는 경우 트래킹 */
          if (entries[0].isIntersecting) {
            trackingBannersRef.current.isOnView = true
            triggerTracker(currentBannerIndex)
          } else {
            trackingBannersRef.current.isOnView = false
          }
        },
        { rootMargin: '0px' }
      )

      if (node) {
        observerRef.current?.observe(node)
      }
    },
    [currentBannerIndex, triggerTracker]
  )

  /** 배너 자동 슬라이드 */
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBannerIndex((prev) => (prev + 1) % bannerLength)
    }, paginationTime)

    return () => clearInterval(interval)
  }, [bannerLength])

  return {
    elementRef,
    currentBannerIndex,
    paginationTime,
  }
}

export default useTextSliderBanner
