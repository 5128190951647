import styled, { useTheme } from 'styled-components'
import { DisplayCollectionSchemeV3 } from '@/apis/rest/generated/types'
import { Text } from '@/components/common'
import { DragScrollWrapper } from '@/components/common/etc'

type SectionCategoryTabProps = {
  categoryTabs: DisplayCollectionSchemeV3[]
  selectedId: number | undefined
  onClick: (selectId: number, name?: string) => void
}

export const SectionCategoryTab = ({ categoryTabs = [], selectedId, onClick }: SectionCategoryTabProps) => {
  const { color } = useTheme()

  return (
    <>
      <DragScrollWrapper>
        <StyledTabList>
          {categoryTabs.map(({ id, name }) => (
            <StyledTabItem
              key={id}
              className={`${selectedId === id ? 'selected' : ''}`}
              data-testid={`home-best-tab-${name}`}
              isSelected={selectedId === id}
              onClick={() => {
                if (!id) {
                  return
                }
                onClick(id, name)
              }}
            >
              <Text.Body3 fontColor={selectedId === id ? color.grayWhite : color.gray900}>{name}</Text.Body3>
            </StyledTabItem>
          ))}
        </StyledTabList>
      </DragScrollWrapper>
    </>
  )
}

const StyledTabList = styled.div`
  width: max-content;
  display: flex;
  justify-content: left;
  gap: 6px;

  & > div:last-child {
    margin-right: ${({
      theme: {
        spacing: { size16 },
      },
    }) => size16};
  }
  padding: 1.6rem 0 1.6rem 1.6rem;
`

const StyledTabItem = styled.div<{ isSelected: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({
      theme: {
        spacing: { size6 },
      },
    }) => size6}
    ${({
      theme: {
        spacing: { size12 },
      },
    }) => size12};
  border-radius: ${({
    theme: {
      spacing: { size20 },
    },
  }) => size20};
  background-color: ${({
    theme: {
      color: { gray700 },
    },
    isSelected,
  }) => (isSelected ? gray700 : 'transparent')};
  border: ${({ theme: { spacing } }) => spacing.size1} solid
    ${({
      theme: {
        color: { gray200 },
      },
      isSelected,
    }) => (isSelected ? 'transparent' : gray200)};
  cursor: pointer;
`
