import { ImageTypeBannerAreaEnum, ImageTypeBannerResponseDto } from '@/apis/rest/generated/types'

/**
 * ViewHomeBannerMain, ViewHomeBannerMiddle, ViewHomeBannerBottom 이벤트 속성값 (Middle과 Bottom은 bannerIndex가 필요하지 않음)
 * @link https://www.notion.so/industrious-ethernet-850/de059ca3023f45a3b2e8368d3273600e?v=6203da09c6bb4e5da00c16afb77880bd
 */
export const convertBannerParamsNew = (bannerData: ImageTypeBannerResponseDto, bannerIndex?: number) => {
  let sectionTitle = ''
  let sectionType = ''

  if (bannerData.area === ImageTypeBannerAreaEnum.MAIN_HOME_TOP_ROLL) {
    sectionType = 'main_banner'
    sectionTitle = '홈 메인배너'
  } else if (bannerData.area === ImageTypeBannerAreaEnum.MAIN_HOME_BOTTOM) {
    sectionType = 'bottom_banner'
    sectionTitle = '홈 하단배너'
  } else if (bannerData.area === ImageTypeBannerAreaEnum.MAIN_HOME_MIDDLE) {
    sectionType = 'middle_banner'
    sectionTitle = '홈 중간배너'
  } else if (bannerData.area === ImageTypeBannerAreaEnum.MAIN_HOME_MIDDLE_MULTI_LINE) {
    sectionType = 'mutiline_banner'
    sectionTitle = '홈 멀티배너'
  }

  // const { rawId, name, bannerInfo } = bannerData

  const link = bannerData.link
  const eventsaleSlug = link.split('/').slice(-1)[0]
  const otherSlug = link.split('/').slice(-1)[0]

  return {
    bannerItemId: bannerData.id,
    bannerItemName: bannerData.title,
    sectionTitle,
    sectionType,
    ...(typeof bannerIndex === 'number' && { bannerIndex }),
    ...(link.includes('/events/') ? { eventsaleSlug } : { otherSlug }),
  }
}
