import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { ProductTypeDefinedByFrontend } from '@/types/product-type-defined-by.frontend'

export const useHomeSectionProductTracker = () => {
  const tracker = useTracker()
  const trackClickHomeSectionProductEvent = ({
    product,
    sectionName,
    sectionIndex,
  }: {
    product: ProductTypeDefinedByFrontend
    sectionName: string
    sectionIndex?: number
  }) => {
    const properties = {
      productId: product.id,
      productName: product.name,
      productPrice: product.price,
      productPurchasePrice: product.salePrice,
      productDiscountRate: product.discountRate,
      brandId: product.brand.id,
      brandName: product.brand.name,
      supplyType: product.supplyType,
      is_shownprice_coupondiscounted: product.hasApplicableProductCoupon,
      sectionName,
      sectionIndex,
    }

    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickHomeSectionProduct, properties)
  }
  return { trackClickHomeSectionProductEvent }
}
