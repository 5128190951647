import { match } from 'ts-pattern'
import { DisplayCollectionTypeEnum, HomeSectionDisplayCollectionResponseDto } from '@/apis/rest/generated/types'
import { HomeDisplayCollectionMagazineBackgroundImage } from '@/components/domains/home/new/home-display-collection-magazine-background-image'
import { HomeSectionMagazineImage } from '@/components/domains/home/new/home-display-collection-magazine-image'
import { HomeDisplayCollectionNewArrivals } from '@/components/domains/home/new/home-display-collection-new-arrivals'
import { HomeDisplayCollectionNormalTypeList } from '@/components/domains/home/new/home-display-collection-normal-type-list'
import { HomeDisplayCollectionTodaySpecialPrice } from '@/components/domains/home/new/home-display-collection-today-special-price'

interface HomeSectionDisplayCollectionProps {
  homeSectionItem: HomeSectionDisplayCollectionResponseDto
  refCallback: (node: HTMLDivElement) => void
}

export const HomeSectionDisplayCollection = ({ homeSectionItem, refCallback }: HomeSectionDisplayCollectionProps) => {
  return (
    <>
      {match(homeSectionItem.displayCollection?.collectionType)
        .with(DisplayCollectionTypeEnum.BEST, () => <></>)
        .with(DisplayCollectionTypeEnum.AD, () => <></>)
        // 오늘의 핫딜
        .with(DisplayCollectionTypeEnum.TODAY_SPECIAL_PRICE, () => (
          <HomeDisplayCollectionTodaySpecialPrice
            key={homeSectionItem.id}
            homeSectionItem={homeSectionItem}
            refCallback={refCallback}
          />
        ))
        // 따끈따끈 신상품
        .with(DisplayCollectionTypeEnum.NEW_ARRIVALS, () => (
          <HomeDisplayCollectionNewArrivals
            key={homeSectionItem.id}
            homeSectionItem={homeSectionItem}
            refCallback={refCallback}
          />
        ))
        // 일반 타입 리스트
        .with(DisplayCollectionTypeEnum.NORMAL, () => (
          <HomeDisplayCollectionNormalTypeList
            key={homeSectionItem.id}
            homeSectionItem={homeSectionItem}
            refCallback={refCallback}
          />
        ))
        // 매거진
        .with(DisplayCollectionTypeEnum.MAGAZINE_IMAGE, () => (
          <HomeSectionMagazineImage
            key={homeSectionItem.id}
            homeSectionItem={homeSectionItem}
            refCallback={refCallback}
          />
        ))
        // 매거진(백그라운드 이미지)
        .with(DisplayCollectionTypeEnum.MAGAZINE_BACKGROUND_IMAGE, () => (
          <HomeDisplayCollectionMagazineBackgroundImage
            key={homeSectionItem.id}
            homeSectionItem={homeSectionItem}
            refCallback={refCallback}
          />
        ))
        .with(DisplayCollectionTypeEnum.BEST_REVIEW, () => <></>)
        .with(undefined, () => <></>)
        .exhaustive()}
    </>
  )
}
