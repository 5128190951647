import parser from 'html-react-parser'
import styled, { useTheme } from 'styled-components'
import { shineLinesCSS } from '@/components/common/skeleton/ShineLinesCSS'
import { Typo, TypoVariant } from '@/components/common/typography'
import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { useCustomRouter } from '@/containers/hooks'
import useTextSliderBanner from '@/containers/hooks/useVerticalSliderBanner'
import FitpetPlusLogoSimpleSvg from '@/images/fitpet_logo_mono_color.svg'
import { HomeTextBanner } from '@/types/banner.type'

interface TextBannerVerticalSliderV2Props {
  isError: boolean
  banners?: HomeTextBanner[]
  isLoading: boolean
  eventTrackerName: {
    click: FitpetMallEventEnum
    view: FitpetMallEventEnum
  }
}

const TextBannerVerticalSliderV2 = ({
  isError,
  banners,
  isLoading,
  eventTrackerName,
}: TextBannerVerticalSliderV2Props) => {
  const { push } = useCustomRouter()
  const { triggerCustomEvent } = useTracker()
  const { color } = useTheme()
  const { elementRef, paginationTime, currentBannerIndex } = useTextSliderBanner<HomeTextBanner>({
    banners,
    viewEventName: eventTrackerName.view,
  })
  const isSingleBanner = banners?.length === 1

  const handleClick = () => {
    const banner = banners?.[currentBannerIndex]
    if (!banner) return

    push(banner.linkUrl)
    triggerCustomEvent(eventTrackerName.click, {
      ...banner.trackerProperties,
    })
  }

  if (isLoading) {
    return <SkeletonBannerSection />
  }

  if (!isError && !banners?.length) {
    return null
  }

  return (
    <StyledContainer ref={elementRef}>
      <StyledSlider onClick={handleClick}>
        {isError && <StyledFitpetPlusLogoSimpleSvg />}
        {isSingleBanner ? (
          <StyledBannerText>{parser(banners[0]?.bannerText ?? '')}</StyledBannerText>
        ) : (
          banners?.map((banner, i) => (
            <StyledSliderItem key={banner.id} isActive={i === currentBannerIndex} paginationTime={paginationTime}>
              <StyledBannerText>
                <Typo variant={TypoVariant.Body4Medium} color={color.gray700}>
                  {parser(banner?.bannerText ?? '')}
                </Typo>
              </StyledBannerText>
            </StyledSliderItem>
          ))
        )}
      </StyledSlider>
    </StyledContainer>
  )
}

const SkeletonBannerSection = styled.div`
  ${shineLinesCSS};
  height: 3.4rem;
`

const StyledFitpetPlusLogoSimpleSvg = styled(FitpetPlusLogoSimpleSvg)`
  width: 3.4rem;
  color: ${(props) => props.theme.color.blue70};
`

const StyledContainer = styled.div`
  position: relative;
  cursor: pointer;
  background-color: ${(props) => props.theme.color.blue30};
  height: 3.4rem;
`

const StyledSlider = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const StyledSliderItem = styled.div<{ paginationTime: number; isActive: boolean }>`
  position: absolute;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;

  ${(props) =>
    props.isActive &&
    `
    animation: ${props.paginationTime}ms slideInAndOut linear forwards;
  `}

  @keyframes slideInAndOut {
    0% {
      pointer-events: unset;
      opacity: 0;
      transform: translateY(24px);
    }
    2% {
      opacity: 1;
    }
    10% {
      opacity: 1;
      transform: translateY(-2px);
    }
    25% {
      opacity: 1;
      transform: translateY(0);
    }
    95% {
      opacity: 1;
      transform: translateY(0);
    }
    98% {
      opacity: 0;
    }
    100% {
      pointer-events: none;
      opacity: 0;
      transform: translateY(-24px);
    }
  }
`

const StyledBannerText = styled.div`
  span.text-emphasis {
    color: ${(props) => props.theme.color.blue500};
  }
`

export default TextBannerVerticalSliderV2
