export enum BestSellerCategoryTypeEnum {
  All = 'all',
  Snack = 'snack',
  Feed = 'feed',
  Supplies = 'supplies',
  Styles = 'styles',
  HealthCare = 'healthcare',
}

export const BestSellerCategoryTypeKoreanText: { [key in BestSellerCategoryTypeEnum]: string } = {
  [BestSellerCategoryTypeEnum.All]: '전체',
  [BestSellerCategoryTypeEnum.Snack]: '간식',
  [BestSellerCategoryTypeEnum.Feed]: '사료',
  [BestSellerCategoryTypeEnum.Supplies]: '용품',
  [BestSellerCategoryTypeEnum.Styles]: '스타일',
  [BestSellerCategoryTypeEnum.HealthCare]: '건강관리',
}

export const bestTabNameToLegacySlug = (tabName?: string) => {
  switch (tabName) {
    case '전체':
      return BestSellerCategoryTypeEnum.All
    case '간식':
      return BestSellerCategoryTypeEnum.Snack
    case '사료':
      return BestSellerCategoryTypeEnum.Feed
    case '용품':
      return BestSellerCategoryTypeEnum.Supplies
    case '스타일':
      return BestSellerCategoryTypeEnum.Styles
    case '건강관리':
      return BestSellerCategoryTypeEnum.HealthCare
    default:
      return ''
  }
}

export const legacySlugToBestTabName = (legacySlug: string) => {
  switch (legacySlug) {
    case BestSellerCategoryTypeEnum.All:
      return '전체'
    case BestSellerCategoryTypeEnum.Snack:
      return '간식'
    case BestSellerCategoryTypeEnum.Feed:
      return '사료'
    case BestSellerCategoryTypeEnum.Supplies:
      return '용품'
    case BestSellerCategoryTypeEnum.Styles:
      return '스타일'
    case BestSellerCategoryTypeEnum.HealthCare:
      return '건강관리'
    default:
      return ''
  }
}
