import { UrlObject } from 'url'
import { useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { HomeSectionDisplayCollectionResponseDto } from '@/apis/rest/generated/types'
import { StyledDivider } from '@/components/common/divider'
import { Countdown } from '@/components/common/ui/countdown'
import { ScrollContainer } from '@/components/domains/etc'
import { HomeSectionTitle } from '@/components/domains/home'
import { Anchor } from '@/components/domains/home/Anchor'
import { TimeSaleProductList } from '@/components/domains/home/new/time-sale-product-list'
import { HomeSectionTitleEnum } from '@/constants/home-section-title.enum'
import { DATE_FORMAT } from '@/constants/legacy/constDate'
import ROUTES from '@/constants/legacy/constRoutes'
import { MinCountForScrollContainerEnum } from '@/constants/min-count-for-scroll-container.enum'
import { Base64ConverterUtil } from '@/utils/base64-converter.util'
import { getDayjs, isNowAfterDayjs, isNowBeforeDayjs } from '@/utils/utilDate'
import type { Dayjs } from 'dayjs'

interface HomeDisplayCollectionTodaySpecialPriceProps {
  homeSectionItem: HomeSectionDisplayCollectionResponseDto
  refCallback: (node: HTMLDivElement) => void
}

export const HomeDisplayCollectionTodaySpecialPrice = ({
  homeSectionItem,
  refCallback,
}: HomeDisplayCollectionTodaySpecialPriceProps) => {
  if (!homeSectionItem.displayCollection) {
    return null
  }

  if (!homeSectionItem.products || homeSectionItem.products.length === 0) {
    return null
  }

  const { color } = useTheme()

  const { displayOrder, displayCollection, products } = homeSectionItem

  const title = displayCollection.name

  const url = {
    pathname: ROUTES.SECTION_DETAIL,
    query: {
      displayCollectionId: Base64ConverterUtil.convertNumberIdToBase64ByKey({
        key: 'DisplayCollectionType',
        id: displayCollection?.id,
      }),
      title,
    },
  }

  return (
    <>
      <Anchor id={title} refCallback={refCallback} />
      <CountdownTitle
        sectionIndex={displayOrder}
        startedAt={getDayjs(displayCollection?.startedAt)}
        endedAt={getDayjs(displayCollection?.endedAt)}
        name={title}
        seeMoreLink={url}
        showSeeMoreButton={products.length > 1}
      />
      <ScrollContainer hideRightButton={products.length < MinCountForScrollContainerEnum.MainHomeSectionProduct}>
        <TimeSaleProductList list={products} sectionIndex={displayOrder} sectionName={title} />
      </ScrollContainer>
      <StyledDivider height="0.1rem" backgroundColor={color.gray70} />
    </>
  )
}

type CountDownTitleProps = {
  sectionIndex: number
  name: string | undefined
  startedAt: Dayjs
  endedAt: Dayjs
  showSeeMoreButton?: boolean
  seeMoreLink?: string | UrlObject
}

const CountdownTitle = ({
  startedAt,
  endedAt,
  name,
  sectionIndex,
  showSeeMoreButton,
  seeMoreLink,
}: CountDownTitleProps) => {
  const [isWaiting, setIsWaiting] = useState(false)
  const [isEnd, setIsEnd] = useState(false)

  useEffect(() => {
    setIsWaiting(isNowBeforeDayjs(startedAt))
    setIsEnd(isNowAfterDayjs(endedAt))
  }, [startedAt, endedAt])

  if (isEnd) return <HomeSectionTitle sectionIndex={sectionIndex}>{HomeSectionTitleEnum.EndTimeSale}</HomeSectionTitle>
  if (isWaiting)
    return <HomeSectionTitle sectionIndex={sectionIndex}>{HomeSectionTitleEnum.WaitTimeSale}</HomeSectionTitle>

  const isWithinOneDay = getDayjs(endedAt).isBefore(getDayjs().add(1, 'days'))
  return (
    <HomeSectionTitle showSeeMoreButton={showSeeMoreButton} seeMoreLink={seeMoreLink} sectionIndex={sectionIndex}>
      <StyledFlexBox>
        {name}&nbsp;
        {endedAt && (
          <StyledCountdown
            value={endedAt.valueOf()}
            format={isWithinOneDay ? DATE_FORMAT.TIME : DATE_FORMAT.DAY}
            onFinish={() => setIsEnd(true)}
          />
        )}
      </StyledFlexBox>
    </HomeSectionTitle>
  )
}

const StyledCountdown = styled(Countdown)`
  font: inherit;
  color: ${({ theme }) => theme.color.red500};
`

const StyledFlexBox = styled.div`
  display: flex;
  justify-content: center;
`
