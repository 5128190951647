import { FC, memo } from 'react'
import styled, { keyframes } from 'styled-components'
import { GlobalColorEnum } from '@/constants/global-color.enum'

const shineLines = keyframes`
  0% {
    background-position: -100px;
  }
  
  40%, 100% {
    background-position: 100px;
  }
`

const SkeletonProductInfo: FC = () => {
  return (
    <StyledSkeletonWrapper>
      <StyledKeywordSkeleton width={93.2} />
      <StyledKeywordSkeleton width={72} />
      <StyledKeywordSkeleton width={38} />
      <StyledKeywordSkeleton width={19} />
    </StyledSkeletonWrapper>
  )
}

export default memo(SkeletonProductInfo)

const StyledSkeletonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const StyledKeywordSkeleton = styled.div<{ width: number }>`
  width: ${({ width }) => width}%;
  &:first-child {
    margin-bottom: 8px;
  }
  height: 16px;
  min-height: 12px;
  margin-bottom: 4px;
  border-radius: 1px;
  background-image: linear-gradient(
    90deg,
    ${GlobalColorEnum.Gray70} 0px,
    ${GlobalColorEnum.Gray50} 40px,
    ${GlobalColorEnum.Gray70} 80px
  );
  background-size: 600px;
  animation: ${shineLines} 3.5s infinite;
`
