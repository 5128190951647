import { useRef } from 'react'
import * as Sentry from '@sentry/nextjs'
import styled, { useTheme } from 'styled-components'
import { useGetPetRecommendedProducts } from '@/apis/rest/generated/hooks'
import { ProductScheme } from '@/apis/rest/generated/types'
import { StyledDivider } from '@/components/common/divider'
import { ScrollContainer } from '@/components/domains/etc'
import { HomeSectionTitle } from '@/components/domains/home'
import { Anchor } from '@/components/domains/home/Anchor'
import { NewProductCard } from '@/components/domains/products/new-product-card/new-product-card'
import { PRODUCT_CARD_SIZES } from '@/components/domains/products/new-product-card/new-product-card.type'
import { HomeSectionTypeEnum } from '@/constants/home-section-type.enum'
import ROUTES from '@/constants/legacy/constRoutes'
import { MinCountForScrollContainerEnum } from '@/constants/min-count-for-scroll-container.enum'
import { PageSizeEnum } from '@/constants/page-size.enum'
import { useHomeTracker } from '@/containers/event-tracker/home.event-tracker'
import { useExecuteOnScrollTop } from '@/containers/hooks/useExecuteOnScrollTop'
import { UserContainer } from '@/containers/users/UserContainer'
import { transformProductTypeDefinedByFrontend } from '@/utils/product/product-card.util'
import { removeScrollbar } from '@/utils/utilCSS'
import type { HomeSectionAnchorBadgeObj } from '@/components/domains/home/new/home-section-list/hooks/use-home-section-anchor-badge'

interface HomeSectionRecommendByPetProductProps {
  sectionIndex: number
  onSectionView: () => void
  setTargetPersonalSection: (item: HomeSectionAnchorBadgeObj) => void
  hasBadge: boolean
}

// 쇼핑 메인 내 상품추천 섹션 로직 고도화(A/B/C/D) C 그룹
export const HomeSectionRecommendByPetProduct = ({
  sectionIndex,
  onSectionView,
  setTargetPersonalSection,
  hasBadge,
}: HomeSectionRecommendByPetProductProps) => {
  const { color } = useTheme()
  const ref = useRef<HTMLDivElement>(null)
  const { me } = UserContainer.useContainer()

  const {
    trackViewHomeRecommendSectionEvent,
    trackClickHomeRecommendSectionMoreEvent,
    trackClickHomeRecommendProductEvent,
  } = useHomeTracker()

  const { data: petRecommendProductData } = useGetPetRecommendedProducts(
    {
      page: 1,
      size: PageSizeEnum.PageSize20,
    },
    {
      query: {
        enabled: !!me,
        onSuccess: (res) => {
          if (!!res?.data?.length) {
            setTargetPersonalSection({ anchorId: HomeSectionTypeEnum.RecommendByPetProduct })
            trackViewHomeRecommendSectionEvent({
              sectionName: `${res?.userPet?.name || ''}에게 이 상품 어때요?`,
              listType: 'personalize_recommend',
            })
          }
        },
        onError: (error) => {
          Sentry.captureMessage('[홈] 펫 추천상품 데이터 요청 실패')
          Sentry.captureException(error)
        },
      },
    }
  )

  useExecuteOnScrollTop({ callback: onSectionView, ref, enabled: hasBadge })

  if (!petRecommendProductData?.data?.length) {
    return null
  }

  const title = `${petRecommendProductData?.userPet?.name || ''}에게\n 이 상품 어때요?`

  const handleClickSeeMore = () => {
    trackClickHomeRecommendSectionMoreEvent({ sectionName: title, listType: 'personalize_recommend' })
  }

  const handleClickProduct = (product: ProductScheme) => {
    trackClickHomeRecommendProductEvent({
      sectionName: title,
      product: {
        id: product.id,
        name: product.name,
        price: product.price,
        salePrice: product.salePrice,
        discountRate: product.discountRate || 0,
        reviewScore: product.review.score,
        reviewCount: product.review.count,
      },
      addLocation: 'home_section',
      listType: 'personalize_recommend',
    })
  }

  return (
    <>
      <StyledContainer data-testid={HomeSectionTypeEnum.RecommendByPetProduct} ref={ref}>
        <Anchor id={HomeSectionTypeEnum.RecommendByPetProduct} />
        <HomeSectionTitle
          onClickSeeMore={handleClickSeeMore}
          showSeeMoreButton
          seeMoreLink={{
            pathname: ROUTES.SECTION_RECOMMEND_PET + `/${petRecommendProductData?.userPet?.id}`,
          }}
          sectionIndex={sectionIndex}
        >
          {title}
        </HomeSectionTitle>
        <ScrollContainer
          hideRightButton={
            petRecommendProductData?.data?.length < MinCountForScrollContainerEnum.MainHomeSectionProduct
          }
        >
          <ListContainer>
            {petRecommendProductData?.data.map((product, index) => (
              <NewProductCard
                key={product.id}
                product={transformProductTypeDefinedByFrontend(product)}
                size={PRODUCT_CARD_SIZES.lg}
                rankingNumber={index}
                onClickProductCard={() => handleClickProduct(product)}
              />
            ))}
          </ListContainer>
        </ScrollContainer>
      </StyledContainer>
      <StyledDivider height="0.1rem" backgroundColor={color.gray70} />
    </>
  )
}

const StyledContainer = styled.div`
  overflow: hidden;
  position: relative;
`

const ListContainer = styled.div`
  ${removeScrollbar};
  display: flex;
  gap: 0.8rem;
  margin-bottom: 2.4rem;

  & > *:first-child {
    margin-left: 16px;
  }

  & > *:last-child {
    padding-right: 16px;
  }

  & > *:last-child {
    padding-right: 16px;
  }
`
