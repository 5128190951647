import styled from 'styled-components'
import { ProductScheme, ProductsListItemResponseDto } from '@/apis/rest/generated/types'
import { NewProductCard } from '@/components/domains/products/new-product-card/new-product-card'
import {
  PRODUCT_CARD_SIZES,
  THUMBNAIL_STATES,
} from '@/components/domains/products/new-product-card/new-product-card.type'
import { HomeSectionTitleEnum } from '@/constants/home-section-title.enum'
import { useHomeTracker } from '@/containers/event-tracker/home.event-tracker'
import { transformProductTypeDefinedByFrontend } from '@/utils/product/product-card.util'
import { removeScrollbar } from '@/utils/utilCSS'

const LIST_LENGTH = 6

export type WeeklyBestProductListProps = {
  list: ProductsListItemResponseDto[]
  sectionIndex: number
  sectionName: HomeSectionTitleEnum
}

export const WeeklyBestProductList = ({ list, sectionIndex, sectionName }: WeeklyBestProductListProps) => {
  const { trackClickHomeBestSectionProductEvent, trackClickHomeWeeklyBestProductEvent } = useHomeTracker()

  const trackClickProduct = (product: ProductScheme) => {
    trackClickHomeWeeklyBestProductEvent(product)
    trackClickHomeBestSectionProductEvent({
      product,
      sectionIndex,
      sectionName,
    })
  }

  return (
    <ListContainer>
      {list.slice(0, LIST_LENGTH).map((product, index) => {
        return (
          <NewProductCard
            key={product.id}
            product={transformProductTypeDefinedByFrontend(product)}
            size={PRODUCT_CARD_SIZES.md}
            rankingNumber={index}
            thumbnailState={THUMBNAIL_STATES.rankingLabel}
            onClickProductCard={() => product && trackClickProduct(product)}
          />
        )
      })}
    </ListContainer>
  )
}

const ListContainer = styled.div`
  ${removeScrollbar};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 0.8rem;
  grid-row-gap: 2.4rem;
  margin: ${({ theme }) => `0 ${theme.spacing.size16} ${theme.spacing.size24}`};
`
