import { useRef, useState } from 'react'
import Slider from 'react-slick'
import styled, { useTheme } from 'styled-components'
import { HomeSectionCategoryResponseDto, ProductsCategoryScheme } from '@/apis/rest/generated/types'
import { CarouselBaseV2 } from '@/components/common/carousels/CarouselBaseV2'
import { StyledDivider } from '@/components/common/divider'
import { FitpetIcon } from '@/components/common/icons/fitpet-icon'
import { Typo, TypoVariant } from '@/components/common/typography'
import { CategoryItemList } from '@/components/domains/home/new/home-section-list/category-section/category-item-list'
import {
  CategoryItem,
  RecommendCategoryRootItem,
} from '@/components/domains/home/new/home-section-list/category-section/types/category-section.type'
import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { IconSizeEnum } from '@/constants/icon-size.enum'
import { FITPETMALL_HOST } from '@/constants/legacy/constEnv'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'

const CATEGORY_FIRST_VIEW_COUNT = 10

interface HomeSectionCategoryProps {
  homeSectionItem: HomeSectionCategoryResponseDto
}

export const HomeSectionCategory = ({ homeSectionItem }: HomeSectionCategoryProps) => {
  if (!homeSectionItem?.categories || homeSectionItem.categories.length === 0) {
    return null
  }
  if (!homeSectionItem.categories[0].children || homeSectionItem.categories[0].children.length === 0) {
    return null
  }

  const rc: RecommendCategoryRootItem = {
    id: 1,
    name: '추천' as const,
    children:
      homeSectionItem.categoryQuickMenus?.map((category) => ({
        ...category,
        id: category.displayOrder,
        slug: '',
      })) || [],
  }

  const normalCategory = homeSectionItem.categories[0].children.map((category) => {
    const children: ProductsCategoryScheme[] = category.children
      ? [
          ...category.children,
          {
            id: 1,
            code: `${category.code}000`,
            depth: 2,
            description: '',
            displayOrder: category.children.length + 1,
            isVisible: true,
            listImage: `${FITPETMALL_HOST}/icons/ic_add.svg`,
            name: '전체',
            parentCategoryId: 0,
            slug: 'all',
            text: category.text,
          },
        ]
      : []
    return { ...category, children }
  })

  const rootCategory: CategoryItem[] = [rc, ...normalCategory]

  const [selectedRootCategoryId, setSelectedRootCategoryId] = useState(1)

  const rootCategoryIndex = rootCategory.findIndex((category) => category.id === selectedRootCategoryId)
  const selectedRootCategory = rootCategory[rootCategoryIndex]

  const { color } = useTheme()
  const tracker = useTracker()
  const sliderRef = useRef<Slider>(null)

  const [isOpen, setIsOpen] = useState(false)

  // 홈 카테고리 사료 탭에서 샘플 제외 - Soi 요청
  // 홈 카테고리에서만 제외하고 카테고리 페이지에서는 출력됨
  const carouselData = rootCategory.map((category) => {
    return {
      id: category.id,
      name: category.name,
      subCategories:
        category.children?.map((productCategory) => productCategory).filter((edge) => edge?.slug !== 'sample') || [],
    }
  })

  const handleSwipeChange = (actionType: 'left' | 'right') => {
    let shouldChangeIndex
    if (actionType === 'right') {
      shouldChangeIndex =
        rootCategoryIndex - 1 < 0 ? rootCategoryIndex + rootCategory.length - 1 : rootCategoryIndex - 1
    } else {
      shouldChangeIndex = (rootCategoryIndex + 1) % rootCategory.length
    }
    setSelectedRootCategoryId(rootCategory[shouldChangeIndex].id)
  }

  const handleCategorySelected = (selectedId: number) => {
    setIsOpen(false)
    const rootCategoryIndex = rootCategory.findIndex((category) => category.id === selectedId)
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(rootCategoryIndex)
    }
    const categoryTypeName = rootCategory.find((item) => item.id === selectedId)?.name
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickHomeCategoryTab, { categoryTypeName })
    setSelectedRootCategoryId(selectedId)
  }

  const handleOpenSeeMore = () => {
    setIsOpen(true)
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickHomeCategoryItem, {
      categoryName: '전체보기',
    })
  }

  return (
    <>
      <StyledCategoryWrapper>
        <StyledTabContainer>
          <StyledRecommendTabButton
            key={1}
            type="button"
            isSelected={selectedRootCategoryId === 1}
            onClick={() => handleCategorySelected(1)}
          >
            <Typo
              variant={selectedRootCategoryId === 1 ? TypoVariant.Body3Bold : TypoVariant.Body3Medium}
              color={selectedRootCategoryId === 1 ? color.grayWhite : color.gray500}
            >
              추천
            </Typo>
          </StyledRecommendTabButton>
          <StyledNormalTabWrapper>
            {rootCategory.slice(1).map(({ id, name }) => {
              const isSelected = selectedRootCategoryId === id
              return (
                <StyledNormalTabButton
                  key={id}
                  type="button"
                  isSelected={selectedRootCategoryId === id}
                  onClick={() => handleCategorySelected(id)}
                >
                  <Typo
                    variant={isSelected ? TypoVariant.Body3Bold : TypoVariant.Body3Medium}
                    color={selectedRootCategoryId === id ? color.grayWhite : color.gray500}
                  >
                    {name}
                  </Typo>
                </StyledNormalTabButton>
              )
            })}
          </StyledNormalTabWrapper>
        </StyledTabContainer>
        <CarouselBaseV2
          ref={sliderRef}
          onSwipe={handleSwipeChange}
          slidesToShow={1}
          lazyLoad
          NextArrow={<></>}
          PreviousArrow={<></>}
          speed={200}
        >
          {carouselData.map((rootCategory) => {
            // 서브 카테고리가 10개 이상이면 9개만 출력하고 더보기 버튼을 출력한다.
            // 서브 카테고리가 10개 미만이면 모든 서브 카테고리를 출력한다.
            const slicedSubCategories =
              rootCategory.subCategories.length > CATEGORY_FIRST_VIEW_COUNT
                ? rootCategory.subCategories.slice(0, CATEGORY_FIRST_VIEW_COUNT - 1)
                : rootCategory.subCategories
            const isShowSeeMore = rootCategory.subCategories.length > CATEGORY_FIRST_VIEW_COUNT
            return (
              <StyledList key={rootCategory.id}>
                {/* 더보기 버튼을 클릭했을 때 - 모든 subCategory 가 출력된다.*/}
                {isOpen &&
                  rootCategory.subCategories.map((subCategory) => (
                    <CategoryItemList
                      key={subCategory.id}
                      subCategory={subCategory}
                      selectedRootCategory={selectedRootCategory}
                    />
                  ))}
                {/* 더보기 버튼을 클릭하지 않았을 때 - subCategory 가 CATEGORY_FIRST_VIEW_COUNT(10개) 이상이면 */}
                {/* 9개만 출력되고 "더보기" 버튼이 출력된다. */}
                {!isOpen &&
                  slicedSubCategories.map((subCategory) => (
                    <CategoryItemList
                      key={subCategory.id}
                      subCategory={subCategory}
                      selectedRootCategory={selectedRootCategory}
                    />
                  ))}
                {!isOpen && isShowSeeMore && (
                  <SeeMoreCol onClick={handleOpenSeeMore}>
                    <StyledSeeMoreItem>
                      <StyledImageRow>
                        <FitpetIcon type="ic-chevron-down" size={IconSizeEnum.Size20} />
                      </StyledImageRow>
                    </StyledSeeMoreItem>
                    <StyledTextRow>
                      <Typo variant={TypoVariant.Body5Regular}>더 보기</Typo>
                    </StyledTextRow>
                  </SeeMoreCol>
                )}
              </StyledList>
            )
          })}
        </CarouselBaseV2>
      </StyledCategoryWrapper>
      <StyledDivider height="0.8rem" backgroundColor={color.gray70} />
    </>
  )
}

const StyledCategoryWrapper = styled.div`
  margin: 1.6rem 0;
`

const StyledList = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 20%);
  grid-row-gap: 1.2rem;
  margin-top: 1.6rem;
  padding: 0 1.2rem;
  min-height: 15rem;
`

const SeeMoreCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledSeeMoreItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.color.gray50};
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.03);
`

const StyledImageRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledTextRow = styled.div`
  margin-top: 0.4rem;
  display: flex;
  justify-content: center;

  div {
    text-align: center;
    letter-spacing: -0.02em;
    color: ${(props) => props.theme.color.gray700};
  }
`

const StyledTabContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.4rem;
`

const StyledRecommendTabButton = styled.button<{ isSelected: boolean }>`
  height: 4rem;
  padding: 0.6rem 1.4rem;
  border: 1px solid ${({ theme }) => theme.color.gray70};
  border-radius: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isSelected, theme }) => (isSelected ? theme.color.blue500 : 'transparent')};
  cursor: pointer;
  outline: none;
`

const StyledNormalTabWrapper = styled.div`
  height: 4rem;
  border: 1px solid ${({ theme }) => theme.color.gray100};
  border-radius: 4rem;
  padding: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
`

const StyledNormalTabButton = styled.button<{ isSelected: boolean }>`
  height: 3.2rem;
  padding: 0.6rem 1.4rem;
  border-radius: 2.4rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isSelected, theme }) => (isSelected ? theme.color.blue500 : 'transparent')};
  cursor: pointer;
  outline: none;
`
