import styled from 'styled-components'
import { ProductsListItemResponseDto } from '@/apis/rest/generated/types'
import { NewProductCard } from '@/components/domains/products/new-product-card/new-product-card'
import { PRODUCT_CARD_SIZES } from '@/components/domains/products/new-product-card/new-product-card.type'
import { useHomeNewProductTracker } from '@/containers/event-tracker/home-new-product.tracker'
import { useHomeSectionProductTracker } from '@/containers/event-tracker/home-section-product.tracker'
import { ProductTypeDefinedByFrontend } from '@/types/product-type-defined-by.frontend'
import { HomeSectionLimit, sliceArray } from '@/utils/home-section/utilHomeSection'
import { transformProductTypeDefinedByFrontend } from '@/utils/product/product-card.util'
import { removeScrollbar } from '@/utils/utilCSS'

type NewArrivalProductListProps = {
  list: ProductsListItemResponseDto[]
  sectionName?: string
  sectionIndex: number
}

const SINGLE_LINE_LIMIT = 7

export const NewArrivalProductList = ({ list, sectionName, sectionIndex }: NewArrivalProductListProps) => {
  const { trackClickHomeNewProductEvent } = useHomeNewProductTracker()
  const { trackClickHomeSectionProductEvent } = useHomeSectionProductTracker()

  const handleClickProductCard = (product: ProductTypeDefinedByFrontend) => {
    trackClickHomeSectionProductEvent({
      product,
      sectionName: sectionName || '',
      sectionIndex,
    })
    trackClickHomeNewProductEvent({ product })
  }

  const isSingleLine = list.length < SINGLE_LINE_LIMIT

  return (
    <ListContainer isSingleLine={isSingleLine}>
      {sliceArray(HomeSectionLimit, list).map((product, index) => {
        const productData = transformProductTypeDefinedByFrontend(product)
        return (
          <NewProductCard
            key={product.id}
            product={productData}
            size={PRODUCT_CARD_SIZES.lg}
            onClickProductCard={() => handleClickProductCard(productData)}
          />
        )
      })}
    </ListContainer>
  )
}

const ListContainer = styled.div<{ isSingleLine: boolean }>`
  ${removeScrollbar};
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: ${({ isSingleLine }) => (isSingleLine ? '1fr' : 'repeat(2, 1fr)')};
  grid-row-gap: 2.4rem;
  grid-column-gap: 0.8rem;

  & > *:first-child,
  & > *:nth-child(2) {
    margin-left: 16px;
  }

  & > *:last-child {
    padding-right: 16px;
  }
`
