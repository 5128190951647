import { useRef, useState } from 'react'
import Slider from 'react-slick'
import styled, { useTheme } from 'styled-components'
import { HomeSectionDisplayCollectionResponseDto } from '@/apis/rest/generated/types'
import { ButtonSlideScrollLeft, ButtonSlideScrollRight } from '@/components/common/buttons'
import { StyledDivider } from '@/components/common/divider'
import { Image } from '@/components/common/images/Image'
import Text from '@/components/common/typeface/Text'
import { Typo, TypoVariant } from '@/components/common/typography'
import { HomeButtonSeeMore } from '@/components/domains/home'
import { Anchor } from '@/components/domains/home/Anchor'
import { NewProductCard } from '@/components/domains/products/new-product-card/new-product-card'
import { PRODUCT_CARD_SIZES } from '@/components/domains/products/new-product-card/new-product-card.type'
import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { S3_HOST_URL } from '@/constants/legacy/constEnv'
import ROUTES from '@/constants/legacy/constRoutes'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { useDrag } from '@/containers/hooks'
import { ProductTypeDefinedByFrontend } from '@/types/product-type-defined-by.frontend'
import { Base64ConverterUtil } from '@/utils/base64-converter.util'
import { transformProductTypeDefinedByFrontend } from '@/utils/product/product-card.util'

const rowSize = 3

interface HomeSectionMagazineImageProps {
  homeSectionItem: HomeSectionDisplayCollectionResponseDto
  refCallback: (node: HTMLDivElement) => void
}

export const HomeSectionMagazineImage = ({ homeSectionItem, refCallback }: HomeSectionMagazineImageProps) => {
  if (!homeSectionItem.displayCollection) {
    return null
  }

  if (!homeSectionItem.products || homeSectionItem.products.length === 0) {
    return null
  }

  const slideRef = useRef<HTMLDivElement | null>(null)
  const slickRef = useRef<Slider | null>(null)
  const { isDragging } = useDrag(slideRef)
  const [dotIndex, setDotIndex] = useState(0)
  const tracker = useTracker()

  const { color } = useTheme()

  const { displayCollection, products } = homeSectionItem
  const title = displayCollection.name

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchThreshold: 100,
    arrows: false,
    rows: rowSize,
    afterChange(index: number) {
      setDotIndex(index)
    },
    ref: slickRef,
  }

  const seeMoreLink = {
    pathname: ROUTES.SECTION_DETAIL,
    query: {
      displayCollectionId: Base64ConverterUtil.convertNumberIdToBase64ByKey({
        key: 'DisplayCollectionType',
        id: displayCollection?.id,
      }),
      title,
    },
  }

  const handleClickProductCard = (product: ProductTypeDefinedByFrontend) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickHomeMagazineImageProduct, {
      productId: product.id,
      productName: product.name,
      productReviewAvgScore: product.reviewScore,
      productReviewTotalCount: product.reviewCount,
      productPurchasePrice: product.salePrice,
      productPrice: product.price,
      productDiscountRate: product.discountRate,
    })
  }

  return (
    <>
      <Anchor id={title} refCallback={refCallback} />
      <Spacing28 />
      <StyledTitleContainer>
        <StyledSeoTitle>
          <Typo variant={TypoVariant.Heading5}>{title}</Typo>
        </StyledSeoTitle>
        <StyledDescription>{displayCollection.description}</StyledDescription>
      </StyledTitleContainer>
      <Spacing20 />
      <Image src={`${S3_HOST_URL}/${displayCollection.backgroundImage}`} alt="magazine-image" />
      <Spacing16 />
      <StyledSliderWrapper ref={slideRef} isDragging={isDragging}>
        <Slider {...sliderSettings}>
          {products?.map((product) => {
            const productData = transformProductTypeDefinedByFrontend(product)
            return (
              <StyledProductCard key={product.id} className="slide-section">
                <NewProductCard
                  product={productData}
                  size={PRODUCT_CARD_SIZES.md}
                  cartType="HORIZONTAL"
                  onClickProductCard={() => handleClickProductCard(productData)}
                />
              </StyledProductCard>
            )
          })}
        </Slider>
        <StyledScrollButtonGroup>
          <ButtonSlideScrollLeft visible={products.length > rowSize} onClick={() => slickRef?.current?.slickPrev()} />
          <ButtonSlideScrollRight visible={products.length > rowSize} onClick={() => slickRef?.current?.slickNext()} />
        </StyledScrollButtonGroup>
      </StyledSliderWrapper>
      <StyledDots>
        {Array.from({ length: Math.ceil(products.length / rowSize) }).map((_, index) => (
          <div key={index} className={`dot ${dotIndex === index ? 'active' : ''}`}></div>
        ))}
      </StyledDots>
      <HomeButtonSeeMore seeMoreLink={seeMoreLink} />
      <StyledDivider height="0.1rem" backgroundColor={color.gray70} />
    </>
  )
}

// 디자인 시스템의 Title Component 사용하지 않음 (reboot에서 진행할 예정)
const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  padding: 0 1.6rem;
  h2 {
    margin-bottom: 0;
  }
`

const StyledSeoTitle = styled.h2`
  text-align: center;
`

const StyledDescription = styled(Text.Body3)`
  text-align: center;
  white-space: pre;
  color: ${(props) => props.theme.color.gray700};
`

const StyledSliderWrapper = styled.div<{ isDragging: boolean }>`
  position: relative;
  min-height: 36rem;
  & .slide-section > div {
    pointer-events: ${({ isDragging }) => (isDragging ? 'none' : 'inherit')};
  }

  .slick-slide {
    /* slider내에 있는 item의 line-height 초기화 */
    line-height: 0;
    > div:not(:last-child) {
      margin-bottom: 0.8rem;
    }
  }
`

const StyledProductCard = styled.section`
  padding-left: 1.6rem;
  padding-right: 1.6rem;
`

const StyledSkeletonBox = styled.div`
  width: 100%;
  height: 24rem;
  background-color: ${(props) => props.theme.color.gray70};
`

const Spacing28 = styled.div`
  height: ${(props) => props.theme.spacing.size28};
`

const Spacing16 = styled.div`
  height: ${(props) => props.theme.spacing.size16};
`

const Spacing20 = styled.div`
  height: ${(props) => props.theme.spacing.size20};
`

const StyledScrollButtonGroup = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  width: 100%;
  pointer-events: none;

  div {
    pointer-events: auto;
  }
`

const StyledDots = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 0.8rem;

  & .dot {
    background-color: ${(props) => props.theme.color.gray100};
    width: 6px;
    height: 6px;
    border-radius: 3px;

    &.active {
      background-color: ${(props) => props.theme.color.blue400};
    }
  }
`
