import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { ProductTypeDefinedByFrontend } from '@/types/product-type-defined-by.frontend'

export const useHomeDisplayProductTracker = () => {
  const tracker = useTracker()
  const trackClickHomeDisplayProductEvent = ({ product }: { product: ProductTypeDefinedByFrontend }) => {
    const properties = {
      productId: product.id,
      productName: product.name,
      productPrice: product.price,
      productDiscountRate: product.discountRate,
      productPurchasePrice: product.salePrice,
      productReviewAvgScore: product.reviewScore,
      productReviewTotalCount: product.reviewCount,
      productIsInStock: !product.isRealSoldOut,
      displayItemId: product.id,
      displayItemName: product.name,
    }

    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickHomeDisplayProduct, properties)
  }
  return { trackClickHomeDisplayProductEvent }
}
