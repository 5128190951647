import { useGetTextTypeBannersV2 } from '@/apis/rest/generated/hooks'
import { TextTypeBannerAreaEnum } from '@/apis/rest/generated/types'
import { usePetType } from '@/containers/hooks/index'
import { makeHomeTextBanners } from '@/utils/banner.util'

export const useGetBanner = (key: TextTypeBannerAreaEnum) => {
  const { getPetType } = usePetType()

  const { data, isError, isLoading } = useGetTextTypeBannersV2(
    { petType: getPetType(), area: [key] },
    {
      query: {
        select: (data) => makeHomeTextBanners(data),
      },
    }
  )

  return { data, isError, isLoading }
}
