import { EventTrackerTypeEnum } from '@/constants/event-tracker-type.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { FitpetMallEventEnum, FitpetMallEventPropertyType } from '@/utils/event-tracker/events'

type ClickMembershipIntroJoinEventParams = {
  location: FitpetMallEventPropertyType['location']
  membershipUsertype: FitpetMallEventPropertyType['membershipUsertype']
  buttonText: FitpetMallEventPropertyType['buttonText']
}
type ViewMembershipIntroEventParams = {
  location: FitpetMallEventPropertyType['location']
  membershipUsertype: FitpetMallEventPropertyType['membershipUsertype']
}
type ClickMembershipJoinTypeSelectEventParams = {
  subscribeType: FitpetMallEventPropertyType['subscribeType']
  membershipPrice: FitpetMallEventPropertyType['membershipPrice']
  membershipDiscountRate?: FitpetMallEventPropertyType['membershipDiscountRate']
  membershipUserType: FitpetMallEventPropertyType['membershipUsertype']
}
type ClickMembershipJoinStartEventParams = ClickMembershipJoinTypeSelectEventParams

type ViewMembershipJoinFinishEventParams = ClickMembershipJoinTypeSelectEventParams
type CompleteMembershipJoinEventParams = ClickMembershipJoinTypeSelectEventParams
type CompleteMembershipFirstJoinEventParams = ClickMembershipJoinTypeSelectEventParams
type ViewMembershipCancelReasonParams = {
  terminatedType: FitpetMallEventPropertyType['terminatedType']
}
type ViewMembershipCancelFinishEventParams = ViewMembershipCancelReasonParams & {
  terminatedReason: FitpetMallEventPropertyType['terminatedReason']
}
type ClickMembershipSettingCancelEventParams = {
  terminatedFamilyMember: FitpetMallEventPropertyType['terminatedFamilyMember']
}
type ClickMembershipSettingCancelCompleteEventParams = ClickMembershipSettingCancelEventParams
type CompletedMembershipSettingCancelEventParams = ClickMembershipSettingCancelEventParams
type CompleteMembershipCanceledEventParams = ViewMembershipCancelFinishEventParams
type ViewMembershipFamilyEventParams = {
  subscribeType: FitpetMallEventPropertyType['subscribeType']
}
type ClickMembershipFamilyInviteShareEventParams = {
  shareType: FitpetMallEventPropertyType['shareType']
  subscribeType: FitpetMallEventPropertyType['subscribeType']
}

type ClickMembershipFamilyInviteEventParams = {
  subscribeType: FitpetMallEventPropertyType['subscribeType']
}
type ClickMembershipHomeInviteEventParams = {
  subscribeType: FitpetMallEventPropertyType['subscribeType']
}
type ViewMembershipHomeEventParams = {
  memberType: FitpetMallEventPropertyType['memberType']
  subscribeType: FitpetMallEventPropertyType['subscribeType']
}
type ViewMembershipRecommendListEventParams = {
  memberType: FitpetMallEventPropertyType['memberType']
  subscribeType: FitpetMallEventPropertyType['subscribeType']
}
type ClickMembershipHomeInviteShareEventParams = {
  shareType: FitpetMallEventPropertyType['shareType']
  subscribeType: FitpetMallEventPropertyType['subscribeType']
}

const useMembershipEventTracker = () => {
  const tracker = useTracker()

  // 멤버십 - 메인 홈 진입점
  const trackClickHomeMembership = () => {
    tracker.triggerEvent(FitpetMallEventEnum.ClickHomeMembership, [
      EventTrackerTypeEnum.Amplitude,
      EventTrackerTypeEnum.Braze,
    ])
  }

  const trackClickHomeBannerMembership = () => {
    tracker.triggerEvent(FitpetMallEventEnum.ClickHomeBannerMembership, [
      EventTrackerTypeEnum.Amplitude,
      EventTrackerTypeEnum.Braze,
    ])
  }

  const trackViewHomeBannerMembership = () => {
    tracker.triggerEvent(FitpetMallEventEnum.ViewHomeBannerMembership, [
      EventTrackerTypeEnum.Amplitude,
      EventTrackerTypeEnum.Braze,
    ])
  }

  // 멤버십 - 멤버십 안내
  const trackClickMembershipIntroJoin = (properties: ClickMembershipIntroJoinEventParams) => {
    tracker.triggerEvent(
      FitpetMallEventEnum.ClickMembershipIntroJoin,
      [EventTrackerTypeEnum.Amplitude, EventTrackerTypeEnum.Braze],
      properties
    )
  }

  const trackViewMembershipIntro = (properties: ViewMembershipIntroEventParams) => {
    tracker.triggerEvent(
      FitpetMallEventEnum.ViewMembershipIntro,
      [EventTrackerTypeEnum.Amplitude, EventTrackerTypeEnum.Braze],
      properties
    )
  }

  // 멤버십 - 멤버십 가입

  const trackClickMembershipJoinTypeSelect = (properties: ClickMembershipJoinTypeSelectEventParams) => {
    tracker.triggerEvent(
      FitpetMallEventEnum.ClickMembershipJoinTypeSelect,
      [EventTrackerTypeEnum.Amplitude, EventTrackerTypeEnum.Braze],
      properties
    )
  }

  const trackClickMembershipJoinStart = (properties: ClickMembershipJoinStartEventParams) => {
    tracker.triggerEvent(
      FitpetMallEventEnum.ClickMembershipJoinStart,
      [EventTrackerTypeEnum.Amplitude, EventTrackerTypeEnum.Braze],
      properties
    )
  }

  const trackViewMembershipJoinFinish = (properties: ViewMembershipJoinFinishEventParams) => {
    tracker.triggerEvent(
      FitpetMallEventEnum.ViewMembershipJoinFinish,
      [EventTrackerTypeEnum.Amplitude, EventTrackerTypeEnum.Braze],
      properties
    )
  }

  const trackCompleteMembershipJoin = (properties: CompleteMembershipJoinEventParams) => {
    tracker.triggerEvent(
      FitpetMallEventEnum.CompleteMembershipJoin,
      [EventTrackerTypeEnum.Amplitude, EventTrackerTypeEnum.Braze, EventTrackerTypeEnum.Appsflyer],
      properties
    )
  }

  const trackCompleteMembershipFirstJoin = (properties: CompleteMembershipFirstJoinEventParams) => {
    tracker.triggerEvent(
      FitpetMallEventEnum.CompleteMembershipFirstJoin,
      [EventTrackerTypeEnum.Amplitude, EventTrackerTypeEnum.Braze, EventTrackerTypeEnum.Appsflyer],
      properties
    )
  }

  const trackCompleteMembershipRegisterError = () => {
    tracker.triggerEvent(FitpetMallEventEnum.CompleteMembershipRegisterError, [
      EventTrackerTypeEnum.Amplitude,
      EventTrackerTypeEnum.Braze,
    ])
  }

  // 멤버십 - 멤버십 해지
  const trackViewMembershipCancelRequest = () => {
    tracker.triggerEvent(FitpetMallEventEnum.ViewMembershipCancelRequest, [
      EventTrackerTypeEnum.Amplitude,
      EventTrackerTypeEnum.Braze,
    ])
  }

  const trackViewMembershipCancelReason = (properties: ViewMembershipCancelReasonParams) => {
    tracker.triggerEvent(
      FitpetMallEventEnum.ViewMembershipCancelReason,
      [EventTrackerTypeEnum.Amplitude, EventTrackerTypeEnum.Braze],
      properties
    )
  }

  const trackViewMembershipCancelFinish = (properties: ViewMembershipCancelFinishEventParams) => {
    tracker.triggerEvent(
      FitpetMallEventEnum.ViewMembershipCancelFinish,
      [EventTrackerTypeEnum.Amplitude, EventTrackerTypeEnum.Braze],
      properties
    )
  }

  const trackCompleteMembershipCanceled = (properties: CompleteMembershipCanceledEventParams) => {
    tracker.triggerEvent(
      FitpetMallEventEnum.CompleteMembershipCanceled,
      [EventTrackerTypeEnum.Amplitude, EventTrackerTypeEnum.Braze],
      properties
    )
  }

  const trackClickMembershipSettingCancel = (properties: ClickMembershipSettingCancelEventParams) => {
    tracker.triggerEvent(
      FitpetMallEventEnum.ClickMembershipSettingCancel,
      [EventTrackerTypeEnum.Amplitude, EventTrackerTypeEnum.Braze],
      properties
    )
  }

  const trackClickMembershipSettingCancelComplete = (properties: ClickMembershipSettingCancelCompleteEventParams) => {
    tracker.triggerEvent(
      FitpetMallEventEnum.ClickMembershipSettingCancelComplete,
      [EventTrackerTypeEnum.Amplitude, EventTrackerTypeEnum.Braze],
      properties
    )
  }

  const trackCompletedMembershipSettingCancel = (properties: CompletedMembershipSettingCancelEventParams) => {
    tracker.triggerEvent(
      FitpetMallEventEnum.CompletedMembershipSettingCancel,
      [EventTrackerTypeEnum.Amplitude, EventTrackerTypeEnum.Braze],
      properties
    )
  }

  // 멤버십 - 멤버십 초대
  const trackViewMembershipFamily = (properties: ViewMembershipFamilyEventParams) => {
    tracker.triggerEvent(
      FitpetMallEventEnum.ViewMembershipFamily,
      [EventTrackerTypeEnum.Amplitude, EventTrackerTypeEnum.Braze],
      properties
    )
  }
  const trackClickMembershipFamilyInvite = (properties: ClickMembershipFamilyInviteEventParams) => {
    tracker.triggerEvent(
      FitpetMallEventEnum.ClickMembershipFamilyInvite,
      [EventTrackerTypeEnum.Amplitude, EventTrackerTypeEnum.Braze],
      properties
    )
  }

  const trackClickMembershipFamilyInviteShare = (properties: ClickMembershipFamilyInviteShareEventParams) => {
    tracker.triggerEvent(
      FitpetMallEventEnum.ClickMembershipFamilyInviteShare,
      [EventTrackerTypeEnum.Amplitude, EventTrackerTypeEnum.Braze],
      properties
    )
  }

  //멤버십 - 멤버십 홈
  const trackViewMembershipHome = (properties: ViewMembershipHomeEventParams) => {
    tracker.triggerEvent(
      FitpetMallEventEnum.ViewMembershipHome,
      [EventTrackerTypeEnum.Amplitude, EventTrackerTypeEnum.Braze],
      properties
    )
  }

  const trackViewMembershipRecommendList = (properties: ViewMembershipRecommendListEventParams) => {
    tracker.triggerEvent(
      FitpetMallEventEnum.ViewMembershipRecommendList,
      [EventTrackerTypeEnum.Amplitude, EventTrackerTypeEnum.Braze],
      properties
    )
  }
  const trackClickMembershipHomeInvite = (properties: ClickMembershipHomeInviteEventParams) => {
    tracker.triggerEvent(
      FitpetMallEventEnum.ClickMembershipHomeInvite,
      [EventTrackerTypeEnum.Amplitude, EventTrackerTypeEnum.Braze],
      properties
    )
  }

  const trackClickMembershipHomeInviteShare = (properties: ClickMembershipHomeInviteShareEventParams) => {
    tracker.triggerEvent(
      FitpetMallEventEnum.ClickMembershipHomeInviteShare,
      [EventTrackerTypeEnum.Amplitude, EventTrackerTypeEnum.Braze],
      properties
    )
  }

  return {
    // 멤버십 - 메인 홈 진입점
    trackClickHomeMembership,
    trackClickHomeBannerMembership,
    trackViewHomeBannerMembership,
    // 멤버십 - 멤버십 안내
    trackClickMembershipIntroJoin,
    trackViewMembershipIntro,
    // 멤버십 - 멤버십 가입
    trackViewMembershipJoinFinish,
    trackCompleteMembershipJoin,
    trackCompleteMembershipFirstJoin,
    trackCompleteMembershipRegisterError,
    trackClickMembershipJoinTypeSelect,
    trackClickMembershipJoinStart,
    // 멤버십 - 멤버십 해지
    trackViewMembershipCancelRequest,
    trackViewMembershipCancelReason,
    trackViewMembershipCancelFinish,
    trackClickMembershipSettingCancel,
    trackClickMembershipSettingCancelComplete,
    trackCompletedMembershipSettingCancel,
    trackCompleteMembershipCanceled,
    // 멤버십 - 멤버십 초대
    trackViewMembershipFamily,
    trackClickMembershipFamilyInvite,
    trackClickMembershipFamilyInviteShare,
    // 멤버십 - 멤버십 홈
    trackViewMembershipHome,
    trackViewMembershipRecommendList,
    trackClickMembershipHomeInvite,
    trackClickMembershipHomeInviteShare,
  }
}

export default useMembershipEventTracker
