import { TextTypeBannerAreaEnum } from '@/apis/rest/generated/types'
import TextBannerVerticalSliderV2 from '@/components/common/slider/TextBannerVerticalSliderV2'
import { useGetBanner } from '@/containers/hooks/useGetBanner'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'

export const HomeBannerReview = () => {
  const { data, isError, isLoading } = useGetBanner(TextTypeBannerAreaEnum.MAIN_HOME_TOP)

  const eventTrackerName = {
    view: FitpetMallEventEnum.ViewHomeTextBanner,
    click: FitpetMallEventEnum.ClickHomeTextBanner,
  }

  return (
    <TextBannerVerticalSliderV2
      banners={data}
      isError={isError}
      isLoading={isLoading}
      eventTrackerName={eventTrackerName}
    />
  )
}
