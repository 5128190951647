import styled from 'styled-components'
import { shineLinesCSS } from '@/components/common/skeleton/ShineLinesCSS'
import { SkeletonLine } from '@/components/domains/category/SkeletonLine'
import SkeletonHomeCategoriesV2 from '@/components/domains/home/skeleton/SkeletonHomeCategoriesV2'
import SkeletonProduct from '@/components/domains/products/skeleton/SkeletonProduct'
import { CAROUSEL_SCREEN_RATIO, SCREEN_SCALE } from '@/constants/legacy/constLayout'

export const SkeletonHome = () => {
  return (
    <>
      <SkeletonCarouselSection />
      <SkeletonBannerSection />
      <SkeletonHomeCategoriesV2 />
      <SkeletonLine height="0.8rem" width="100%" />
      <SkeletonProductSectionContainer>
        <SkeletonTitle />
        <SkeletonProductWrapper>
          <SkeletonProduct />
        </SkeletonProductWrapper>
        <SkeletonProductWrapper>
          <SkeletonProduct />
        </SkeletonProductWrapper>
      </SkeletonProductSectionContainer>
    </>
  )
}

const SkeletonCarouselSection = styled.div`
  ${shineLinesCSS};
  /**
   * 홈 캐러셀 슬라이드 스켈레톤 높이값 계산
   * 100vw 기준 (최대 가로 사이즈 576px) 캐러셀 슬라이드 1/2.067 비율로 계산  
   */
  height: calc(
    min(100vw, ${SCREEN_SCALE.MAX_DEVICE_WIDTH}px) * (${CAROUSEL_SCREEN_RATIO.HEIGHT} / ${CAROUSEL_SCREEN_RATIO.WIDTH})
  );
`

const SkeletonBannerSection = styled.div`
  ${shineLinesCSS};
  height: 3.4rem;
`

const SkeletonProductSectionContainer = styled.div`
  padding: 2.4rem 1.6rem 1.6rem;
`

const SkeletonTitle = styled.div`
  ${shineLinesCSS};
  height: 2.4rem;
  margin-bottom: 1.6rem;
  border-radius: 0.6rem;
`

const SkeletonProductWrapper = styled.div`
  display: flex;
  gap: 0.8rem;
  overflow: hidden;
  padding-bottom: 4.8rem;
`
