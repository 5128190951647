import { useCallback, useRef, useState } from 'react'
import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import type { ImageTypeBannerResponseDto } from '@/apis/rest/generated/types'

const defaultBannerIndex = 0
const defaultSliderSettings = {
  dots: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3000,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
}

type TrackingBannersRef = {
  /** 트래킹한 배너 리스트 */
  trackedBanners: Record<number, boolean>
  /** 배너가 현재 화면에 보이고 있는지 여부 */
  isOnView: boolean
}

/**
 * react-slick 을 사용한 컴포넌트에서 슬라이드 기본 설정과 이벤트 트래커를 제공하는 훅
 */
export const useReactSlickBanner = (viewEventName: FitpetMallEventEnum, banners?: ImageTypeBannerResponseDto[]) => {
  const observerRef = useRef<IntersectionObserver | null>(null)
  const { triggerCustomEvent } = useTracker()
  const [currentBannerIndex, setCurrentBannerIndex] = useState(defaultBannerIndex)
  const trackingBannersRef = useRef<TrackingBannersRef>({ trackedBanners: {}, isOnView: false })

  const triggerTracker = useCallback(
    (idx: number) => {
      if (banners === undefined) return
      /** 한번 View 이벤트를 트래킹한 배너는 다시 트래킹하지 않음 */
      if (banners?.length && !trackingBannersRef.current.trackedBanners[idx]) {
        // const trackerProperties = banners[idx]?.trackerProperties
        // triggerCustomEvent(viewEventName, {
        //   ...trackerProperties,
        // })
        // trackingBannersRef.current.trackedBanners[idx] = true
      }
    },
    [banners, triggerCustomEvent, viewEventName]
  )

  const elementRef = useCallback(
    (node: HTMLDivElement) => {
      observerRef.current = new IntersectionObserver(
        (entries) => {
          /** viewport에 보이는 경우 트래킹 */
          if (entries[0].isIntersecting) {
            trackingBannersRef.current.isOnView = true
            triggerTracker(currentBannerIndex)
          } else {
            trackingBannersRef.current.isOnView = false
          }
        },
        { rootMargin: '0px' }
      )

      if (node) {
        observerRef.current?.observe(node)
      }
    },
    [currentBannerIndex, triggerTracker]
  )

  const settings = {
    ...defaultSliderSettings,
    afterChange: (current: number) => {
      setCurrentBannerIndex(current)

      /** viewport 에 보이기전까지는 트래킹하지 않음 */
      if (trackingBannersRef.current.isOnView) {
        triggerTracker(current)
      }
    },
  }

  return {
    elementRef,
    currentBannerIndex,
    settings,
  }
}
