import { Children, forwardRef, PropsWithChildren, ReactNode, useCallback, useState } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import { ButtonSlideScrollLeft, ButtonSlideScrollRight } from '@/components/common/buttons'
import { Z_INDEXES } from '@/constants/legacy/constLayout'

const SLIDER_SETTINGS = {
  speed: 500,
  touchThreshold: 100,
  autoplaySpeed: 5000,
  easing: 'linear',
  infinite: true,
}

type CarouselBaseProps = {
  disableBodyScrollWhileSliding?: boolean
  autoplay?: boolean
  centerMode?: boolean
  lazyLoad?: boolean
  adaptiveHeight?: boolean
  initialSlide?: number
  centerPadding?: string
  className?: string
  slidesToShow?: number
  dots?: boolean
  responsive?: {
    breakpoint: number
    settings: {
      centerPadding: string
    }
  }[]
  onIndexChange?: (currentIndex: number) => void
  onSwipe?: (actionType: 'left' | 'right') => void
  arrowTopPosition?: number
  NextArrow?: ReactNode
  PreviousArrow?: ReactNode
  speed?: number
}

export const CarouselBaseV2 = forwardRef<Slider, PropsWithChildren<CarouselBaseProps>>(
  (
    {
      onIndexChange,
      arrowTopPosition = 130,
      initialSlide = 0,
      adaptiveHeight = true,
      NextArrow,
      PreviousArrow,
      children,
      ...sliderProps
    },
    ref
  ) => {
    const [dragging, setDragging] = useState(false)

    const handleBeforeChange = useCallback(() => {
      setDragging(true)
    }, [setDragging])

    const handleAfterChange = useCallback(() => {
      setDragging(false)
    }, [setDragging])

    return (
      <StyledWrapper>
        {/* @ts-ignore */}
        <Slider
          ref={ref}
          nextArrow={NextArrow || <RenderNextArrow topPosition={arrowTopPosition} />}
          prevArrow={PreviousArrow || <RenderPrevArrow topPosition={arrowTopPosition} />}
          beforeChange={handleBeforeChange}
          afterChange={(currentSlide: number) => {
            handleAfterChange()
            onIndexChange?.(currentSlide)
          }}
          initialSlide={initialSlide}
          adaptiveHeight={adaptiveHeight}
          {...SLIDER_SETTINGS}
          {...sliderProps}
        >
          {Children.map(children, (child) => {
            /**
             * 캐러셀 드래그 시 클릭 이벤트 막기 제거 관련 내용
             * <div onClickCapture={handleOnItemClick}>{child}</div>
             * slick 클릭 막기 시 슬라이드 내 클릭 요소 이슈로 인해 slick 옵션 중 touchThreshold 값을 높여주어 클릭 이슈를 개선하도록 함.
             * @see {@link https://lily-im.tistory.com/109}
             */
            return <div>{child}</div>
          })}
        </Slider>
      </StyledWrapper>
    )
  }
)

const StyledWrapper = styled.div`
  .slick-dots {
    bottom: 20px;
  }

  .slick-dots li {
    margin: 0;
    width: 15px;
    height: 15px;
  }

  .slick-dots li button {
    width: 15px;
    height: 15px;
  }

  .slick-dots li button:before {
    font-size: 30px;
  }
`

const RenderNextArrow = (props: any) => {
  const { onClick, topPosition } = props
  return (
    <NextArrowContainer top={topPosition}>
      <ButtonSlideScrollRight visible onClick={onClick} />
    </NextArrowContainer>
  )
}

const RenderPrevArrow = (props: any) => {
  const { onClick, topPosition } = props
  return (
    <PrevArrowContainer top={topPosition}>
      <ButtonSlideScrollLeft visible onClick={onClick} />
    </PrevArrowContainer>
  )
}

const NextArrowContainer = styled.div<{ top: number }>`
  position: absolute;
  right: 0;
  top: ${({ top }) => `${top}px`};
  z-index: ${Z_INDEXES.CAROUSEL_ARROW_BUTTON};
`

const PrevArrowContainer = styled.div<{ top: number }>`
  position: absolute;
  left: 0;
  top: ${({ top }) => `${top}px`};
  z-index: ${Z_INDEXES.CAROUSEL_ARROW_BUTTON};
`
CarouselBaseV2.displayName = 'CarouselBaseV2'
