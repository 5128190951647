import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { FIXED_HEIGHT } from '@/constants/legacy/constLayout'
import { RootState } from '@/stores/store'

interface AnchorProps {
  id?: string
  refCallback?: (node: HTMLDivElement) => void
  top?: number
}

export const Anchor = ({ id, refCallback, top }: AnchorProps) => {
  const isShowTopSlimBanner = useSelector((state: RootState) => state.reduxUIReducers.isShowTopSlimBanner)
  const initTop =
    FIXED_HEIGHT.HEADER +
    FIXED_HEIGHT.HEADER_HOME_NAVIGATION_BAR +
    (isShowTopSlimBanner ? FIXED_HEIGHT.TOP_SLIM_BANNER_HEIGHT : 0)
  return (
    <AnchorContainer>
      <AnchorTarget id={id} top={top} initTop={initTop} ref={refCallback} />
    </AnchorContainer>
  )
}

const AnchorContainer = styled.div`
  position: relative;
`

const AnchorTarget = styled.div<{ top?: number; initTop: number }>`
  height: 0.1rem;
  position: absolute;
  top: ${({ top, initTop }) => (top !== undefined ? `${top + -FIXED_HEIGHT.HEADER / 10}rem` : `-${initTop / 10}rem`)};
`
