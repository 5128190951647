import { UrlObject } from 'url'
import { FC } from 'react'
import styled, { useTheme } from 'styled-components'
import { ValueOf } from 'type-fest'
import { LinkButton } from '@/components/common/buttons'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { Typo, TypoVariant } from '@/components/common/typography'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { SEE_ALL } from '@/constants/legacy/constText'
import { GlobalTheme } from '@/styles/globalTheme'

type HomeButtonSeeMoreProps = {
  seeMoreLink: string | UrlObject
  text?: string
  className?: string
  isWhiteFont?: boolean
}

const HomeButtonSeeMore: FC<HomeButtonSeeMoreProps> = ({
  seeMoreLink,
  text = SEE_ALL,
  className,
  isWhiteFont = false,
}) => {
  const { iconSize, color } = useTheme()

  return (
    <StyledButtonContainer className={className}>
      <LinkButton url={seeMoreLink}>
        <div className="btn">
          <Typo color={isWhiteFont ? color.grayWhite : color.grayBlack} variant={TypoVariant.Body1Medium}>
            {text} 보기
          </Typo>
          <FlatIcon
            type={IconNameEnum.IcArrowRight}
            size={iconSize.size16}
            color={isWhiteFont ? color.grayWhite : color.grayBlack}
          />
        </div>
      </LinkButton>
    </StyledButtonContainer>
  )
}

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  border: 1px solid ${GlobalColorEnum.Gray200};
  border-radius: 2.4rem;
  margin: 2.4rem 1.6rem 2.8rem;

  .btn {
    display: flex;
    align-items: center;
    column-gap: 0.4rem;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
  }
`

export default HomeButtonSeeMore
