import styled from 'styled-components'
import { HomeSectionBannerResponseDto, ImageTypeBannerResponseDto } from '@/apis/rest/generated/types'
import { LinkButton } from '@/components/common/buttons'
import { Image } from '@/components/common/images/Image'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { useIntersectionObserver } from '@/containers/hooks'
import { convertBannerParamsNew } from '@/utils/event-tracker/convertParams/convertBannerParamsNew'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'
import { createFullURL } from '@/utils/utilURL'

interface HomeMultiLineBannerProps {
  homeSectionItem: HomeSectionBannerResponseDto
}

export const HomeBannerMultiLine = ({ homeSectionItem }: HomeMultiLineBannerProps) => {
  return (
    <>
      {homeSectionItem.imageTypeBanners?.map((banner) => (
        <BannerItem key={banner.id} banner={banner} />
      ))}
    </>
  )
}

interface BannerItemProps {
  banner: ImageTypeBannerResponseDto
}

const BannerItem = ({ banner }: BannerItemProps) => {
  const tracker = useTracker()

  const clickBannerHandler = (): void => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickHomeBanner, convertBannerParamsNew(banner))
  }

  const { elementRef } = useIntersectionObserver(
    () => tracker.triggerCustomEvent(FitpetMallEventEnum.ViewHomeBanner, convertBannerParamsNew(banner)),
    { rootMargin: '0px' }
  )

  return (
    <LinkButton key={banner.id} url={banner.link} routeType="push" onClick={clickBannerHandler}>
      <ImageContainer className="multi-line" ref={elementRef}>
        <Image alt="home-multi-banner" src={createFullURL(banner.imageUrl)} objectFit="cover" aspectRatio="390/56" />
      </ImageContainer>
    </LinkButton>
  )
}

const ImageContainer = styled.div`
  width: 100%;
  position: relative;
  height: unset;
  margin: 0;
`
