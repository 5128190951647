export enum HomeSectionTypeEnum {
  DisplayCollection = 'DISPLAY_COLLECTION',
  Banner = 'BANNER',
  Category = 'CATEGORY',
  WeeklyBest = 'WEEKLY_BEST',
  Brand = 'BRAND',
  BestReview = 'BEST_REVIEW',
  RecommendFrequentlyBoughtProduct = 'RECOMMEND_FREQUENTLY_BOUGHT_PRODUCT',
  RecommendRecentlyBoughtProduct = 'RECOMMEND_RECENTLY_BOUGHT_PRODUCT',
  RecommendByPetProduct = 'RECOMMEND_BY_PET_PRODUCT',
  RecommendByPetProductByPersonalize = 'RECOMMEND_BY_PET_PRODUCT_BY_PERSONALIZE',
}
