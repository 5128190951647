import styled, { useTheme } from 'styled-components'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { Typo, TypoVariant } from '@/components/common/typography'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { FIXED_HEIGHT } from '@/constants/legacy/constLayout'
import { isInApp } from '@/utils/utilCommon'

interface AnchorBadgeButtonProps {
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

const BOTTOM_POSITION = 16

export const AnchorBadgeButton = ({ onClick }: AnchorBadgeButtonProps) => {
  const { color, iconSize } = useTheme()

  const getBottom = () => {
    if (isInApp()) {
      return BOTTOM_POSITION
    }
    return FIXED_HEIGHT.BOTTOM_TAB_NAVIGATOR + BOTTOM_POSITION
  }

  return (
    <StyledAnchorBadgeButtonContainer>
      <AnchorBottomBadge data-testid="home-bottom-badge" onClick={onClick} bottom={getBottom()}>
        <Typo variant={TypoVariant.Body1Medium} color={color.grayWhite}>
          이 상품 필요하세요?
        </Typo>
        <FlatIcon type={IconNameEnum.IcBottom} color={color.grayWhite} size={iconSize.size16} />
      </AnchorBottomBadge>
    </StyledAnchorBadgeButtonContainer>
  )
}

const StyledAnchorBadgeButtonContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`

const AnchorBottomBadge = styled.div<{ bottom: number }>`
  background-color: ${({ theme: { color } }) => color.gray800};
  height: 4rem;
  width: 17rem;
  bottom: ${({ bottom }) => `calc(env(safe-area-inset-bottom) + ${bottom}px)`};
  position: fixed;
  border-radius: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  gap: 0.4rem;
`
