import { FC } from 'react'
import Head from 'next/head'
import { InitSocialMetaTag } from '@/constants/initial-social-meta-tag.enum'
import { FITPETMALL_HOST } from '@/constants/legacy/constEnv'
import { useCustomRouter } from '@/containers/hooks'
import { JsonLdTotalType } from '@/types/seo-json-ld.type'

interface SeoHeaderProps {
  title?: string
  metaTitle?: string
  metaDescription?: string
  pathname?: string
  imageUrl?: string
  jsonLd?: JsonLdTotalType
}

/**
 * Seo 개선을 위한 컴포넌트 <head /> 영역의 meta 태그등을 선언합니다
 *
 * @param title
 * @param metaTitle - 없을시 title
 * @param metaDescription
 * @param pathname - canonicalPathname
 * @param imageUrl
 * @param jsonLd
 * @constructor
 */
const SeoHeader: FC<SeoHeaderProps> = ({
  title,
  metaTitle = title,
  metaDescription = '',
  pathname,
  imageUrl,
  jsonLd,
}) => {
  const { pathname: currentPathname } = useCustomRouter()
  const canonicalLink = `${FITPETMALL_HOST}${pathname ?? currentPathname}`

  return (
    <Head>
      <title>{title ?? InitSocialMetaTag.Title}</title>
      <meta name="description" content={metaDescription ?? InitSocialMetaTag.Description} />
      <meta property="og:title" content={metaTitle} key="title" />
      <meta property="og:description" content={metaDescription} key="description" />
      <meta property="og:image" content={imageUrl ?? InitSocialMetaTag.Image} key="image" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={canonicalLink} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={imageUrl ?? InitSocialMetaTag.Image} />
      <meta property="og:image:width" content="1200"></meta>
      <meta property="og:image:height" content="630"></meta>
      <link rel="canonical" href={canonicalLink} />
      {jsonLd && <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }} />}
    </Head>
  )
}

export default SeoHeader
