import styled, { useTheme } from 'styled-components'
import { ProductsListItemResponseDto } from '@/apis/rest/generated/types'
import { TypoVariant } from '@/components/common/typography'
import { NewProductCard } from '@/components/domains/products/new-product-card/new-product-card'
import {
  PRODUCT_CARD_SIZES,
  THUMBNAIL_STATES,
} from '@/components/domains/products/new-product-card/new-product-card.type'
import { useHomeSectionProductTracker } from '@/containers/event-tracker/home-section-product.tracker'
import { useHomeSpecialSaleProductTracker } from '@/containers/event-tracker/home-special-sale-product.tracker'
import { ProductTypeDefinedByFrontend } from '@/types/product-type-defined-by.frontend'
import { HomeSectionLimit, sliceArray } from '@/utils/home-section/utilHomeSection'
import { transformProductTypeDefinedByFrontend } from '@/utils/product/product-card.util'
import { removeScrollbar } from '@/utils/utilCSS'
import { percentText } from '@/utils/utilNumber'

export type TimeSaleProductListProps = {
  list: ProductsListItemResponseDto[]
  sectionName?: string
  sectionIndex: number
}

export const TimeSaleProductList = ({ list, sectionName, sectionIndex }: TimeSaleProductListProps) => {
  const { color } = useTheme()
  const { trackClickHomeSectionProductEvent } = useHomeSectionProductTracker()
  const { trackClickHomeSpecialSaleProductEvent } = useHomeSpecialSaleProductTracker()

  const handleClickProductCard = (product: ProductTypeDefinedByFrontend) => {
    trackClickHomeSectionProductEvent({
      product,
      sectionName: sectionName || '',
      sectionIndex,
    })
    trackClickHomeSpecialSaleProductEvent({ product })
  }

  return (
    <StyledListContainer>
      {sliceArray(HomeSectionLimit, list).map((product, index) => {
        const productData = transformProductTypeDefinedByFrontend(product)

        const label = !!product.discountRate
          ? {
              value: percentText(productData.discountRate),
              fontColor: color.grayWhite,
              backgroundColor: color.red500,
              variant: TypoVariant.Body5Bold,
            }
          : undefined

        return (
          <StyledProductCardContainer key={product.id}>
            <NewProductCard
              product={productData}
              size={PRODUCT_CARD_SIZES.lg}
              thumbnailState={THUMBNAIL_STATES.basicLabel}
              rankingNumber={index}
              label={label}
              onClickProductCard={() => handleClickProductCard(productData)}
            />
          </StyledProductCardContainer>
        )
      })}
    </StyledListContainer>
  )
}

const StyledListContainer = styled.div`
  ${removeScrollbar};
  display: flex;
  gap: 0.8rem;
  margin-bottom: 2.4rem;

  & > *:first-child {
    margin-left: 16px;
  }

  & > *:last-child {
    padding-right: 16px;
  }
`

const StyledProductCardContainer = styled.div`
  position: relative;
`
