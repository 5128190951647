export const RETURN_EXCHANGE_NOTICE = {
  FIRST:
    '· 교환/반품 신청시 핏펫 고객센터(02-6339-1800)에서 안내 전화가 나갈 예정입니다. 해당 번호로 전화가 오면 꼭 받아주세요.',
  SECOND:
    '· 회수 상품 검수 완료 시 환불 예정 금액이 환불되며, 사용한 적립금은 자동 복구됩니다. 쿠폰은 유효기간이 남아 있는 경우에 한해 자동 복구됩니다.',
}
export const USER_COUPON_NOTICES = [
  '쿠폰은 적립금과 동시에 사용할 수 있습니다.',
  '상품 쿠폰은 상품 단위로만 적용할 수 있으며, 장바구니 쿠폰은 전체 상품에 적용할 수 있습니다.',
  '쿠폰은 배송비를 제외한 주문 상품 금액 기준으로 적용됩니다.',
  '취소 및 반품 시 사용한 쿠폰은 유효기간이 남아 있는 경우에 한해 복구됩니다.',
  '부분 취소/반품 시, 남아있는 주문 상품 금액이 쿠폰 사용 조건에 미달될 경우, 전체 환불 후 재주문을 해주셔야 합니다.',
  '만료 쿠폰은 최근 1개월 내 사용·소멸된 쿠폰에 한해 내역 확인이 가능합니다.',
  '회원가입 축하 쿠폰은 첫 구매 시에만 사용이 가능합니다.',
  '쿠폰이 발급되지 않은 경우 마이페이지 하단의 고객센터로 문의해 주세요.',
]

export const CANCEL_NOTICE =
  '주문취소 완료 시 환불예정 금액으로 환불이 진행됩니다. 사용한 적립금은 자동 복원되며, 쿠폰은 유효기간이 남아있는 경우 자동 복원됩니다.'

export const RETURN_NOTICE = '환불 정보는 환불 처리 상태에 따라 달라질 수 있습니다.'

export const SEE_ALL = '더'
