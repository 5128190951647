import { useEffect, useState } from 'react'
import { DateFormatEnum } from '@/constants/date-type.enum'
import { useHomeTracker } from '@/containers/event-tracker/home.event-tracker'
import { convertDateWithFormat, isTargetDateBeforeReference } from '@/utils/utilDate'
import { localLastActionHomeBottomBadge } from '@/utils/utilLocalStorage'
import type { HomeSectionTypeEnum } from '@/apis/rest/generated/types'

/**
 * `HomeSection` UI의 `AnchorBadge` UI 노출 관장하는 데이터 id 타입
 */
type AnchorBadgeId = Extract<HomeSectionTypeEnum, 'RECOMMEND_BY_PET_PRODUCT' | 'RECOMMEND_RECENTLY_BOUGHT_PRODUCT'>

/**
 * `HomeSection` UI의 `AnchorBadge` UI 노출 관장하는 데이터 타입
 */
export type HomeSectionAnchorBadgeObj = {
  /** Anchor 작동되는 홈섹션의 유니온 타입 */
  anchorId: AnchorBadgeId
  /** Anchor 작동 가능 여부 */
  enabled?: boolean
  /** Anchor 작동되는 홈섹션의 데이터 호출 완료 여부 */
  finished?: boolean
}

/**
 * `AnchorBadge`가 이동시켜야할 `HomeSection` id의 순서 배열
 */
const ANCHOR_BADGE_ORDER: AnchorBadgeId[] = ['RECOMMEND_RECENTLY_BOUGHT_PRODUCT', 'RECOMMEND_BY_PET_PRODUCT']

type UseHomeSectionAnchorBadge = () => {
  /** `AnchorBadge`가 표시되는지 여부 */
  isShowsAnchorBadge: boolean
  /** `AnchorBadge` 버튼 클릭 핸들러 */
  handleClickButtonAnchorBadge: () => void
  /** `AnchorBadge` 스크롤 트래킹 핸들러 */
  handleViewAnchorBadge: () => void
  /** `AnchorBadge` 데이터 업데이트 핸들러 */
  handleUpdateAnchorBadge: (obj: HomeSectionAnchorBadgeObj) => void
}

export const useHomeSectionAnchorBadge: UseHomeSectionAnchorBadge = () => {
  const { trackClickHomeNudgeMessageEvent } = useHomeTracker()

  const [isShow, setIsShow] = useState(false)
  const [anchorBadgeObj, setAnchorBadgeObj] = useState<HomeSectionAnchorBadgeObj[]>([])

  const lastActionHomeBottomBadge = localLastActionHomeBottomBadge?.load()

  const saveLocalLastActionHomeBottomBadge = () => {
    localLastActionHomeBottomBadge.save(
      convertDateWithFormat({ date: new Date(), format: DateFormatEnum['YYYY-MM-DD'] })
    )
  }

  const handleClickButtonAnchorBadge = () => {
    if (anchorBadgeObj.length <= 0) {
      return
    }

    const data = anchorBadgeObj.find((anchor) => anchor.enabled && anchor.finished)
    if (!data) {
      return
    }

    saveLocalLastActionHomeBottomBadge()
    trackClickHomeNudgeMessageEvent()
    setIsShow(false)
    location.href = `#${data.anchorId}`
  }

  const handleViewAnchorBadge = () => {
    saveLocalLastActionHomeBottomBadge()
    setIsShow(false)
  }

  const handleUpdateAnchorBadge = (obj: HomeSectionAnchorBadgeObj) => {
    const baseObj: HomeSectionAnchorBadgeObj = { enabled: true, finished: true, ...obj }
    const hasAnchorData = anchorBadgeObj.find((anchor) => anchor.anchorId === baseObj.anchorId)

    if (hasAnchorData) {
      return setAnchorBadgeObj((prev) => prev.map((a) => (a.anchorId === baseObj.anchorId ? baseObj : a)))
    }

    setAnchorBadgeObj((prev) =>
      [...prev, baseObj].sort((a, b) => ANCHOR_BADGE_ORDER.indexOf(a.anchorId) - ANCHOR_BADGE_ORDER.indexOf(b.anchorId))
    )
  }

  useEffect(() => {
    const isDateBefore = isTargetDateBeforeReference({
      targetDate: lastActionHomeBottomBadge,
      referenceDate: new Date(),
    })

    if (isDateBefore) {
      setIsShow(true)
    }
  }, [lastActionHomeBottomBadge])

  const isShowsAnchorBadge = isShow && anchorBadgeObj.some((anchor) => anchor.enabled && anchor.finished)

  return {
    isShowsAnchorBadge,
    handleClickButtonAnchorBadge,
    handleViewAnchorBadge,
    handleUpdateAnchorBadge,
  }
}
