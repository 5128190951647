import styled from 'styled-components'
import { shineLinesCSS } from '@/components/common/skeleton/ShineLinesCSS'

const SkeletonCategoryCount = 10

const SkeletonHomeCategoriesV2 = () => {
  return (
    <StyledSkeletonContainer>
      <SkeletonCategoryTabSection>
        <SkeletonTab />
      </SkeletonCategoryTabSection>
      <SkeletonCategoryButtonSection>
        {Array(SkeletonCategoryCount)
          .fill(0)
          .map((item, index) => (
            <CategoryButtonItem key={index}>
              <SkeletonButton />
              <SkeletonText />
            </CategoryButtonItem>
          ))}
      </SkeletonCategoryButtonSection>
    </StyledSkeletonContainer>
  )
}
const StyledSkeletonContainer = styled.div`
  padding: 1.6rem 0 0.4rem;
`

const SkeletonCategoryTabSection = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.8rem;
  align-items: center;
`

const SkeletonTab = styled.div`
  ${shineLinesCSS};
  width: 30rem;
  height: 3rem;
  border-radius: 0.4rem;
`

const SkeletonCategoryButtonSection = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 20%);
  justify-items: center;
  grid-row-gap: 1.2rem;
  margin: 1.6rem 0;
  padding: 0 1.2rem;
`

const CategoryButtonItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const SkeletonButton = styled.div`
  ${shineLinesCSS};
  align-items: center;
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
`

const SkeletonText = styled.div`
  ${shineLinesCSS};
  align-items: center;
  width: 4.8rem;
  height: 1.6rem;
  margin-top: 0.6rem;
  border-radius: 0.4rem;
`

export default SkeletonHomeCategoriesV2
