import { FC, memo } from 'react'
import styled from 'styled-components'
import { SkeletonProductInfo, SkeletonProductImage } from '@/components/domains/products/skeleton'
import { CardLayoutEnum } from '@/constants/card-layout.enum'
import { ProductThumbnailSizeEnum } from '@/constants/product-thumbnail-size.enum'

type SkeletonProductCardProps = {
  size: ProductThumbnailSizeEnum
  type: CardLayoutEnum
}

const SkeletonProductCard: FC<SkeletonProductCardProps> = ({ size, type }) => {
  return (
    <StyledWrapperContainer align={type}>
      <SkeletonProductImage imageSize={size} skeletonType={type} />
      <StyledContentCol size={size}>
        <SkeletonProductInfo />
      </StyledContentCol>
    </StyledWrapperContainer>
  )
}

export default memo(SkeletonProductCard)

const StyledWrapperContainer = styled.div<{ align: CardLayoutEnum }>`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ align }) => (align === CardLayoutEnum.Horizontal ? 'row' : 'column')};
  gap: ${({ align }) => (align === CardLayoutEnum.Vertical ? 8 : 16)}px;
`

const StyledContentCol = styled.div<{ size: ProductThumbnailSizeEnum }>`
  width: ${({ size }) => size};
`
