import styled, { useTheme } from 'styled-components'
import { HomeSectionDisplayCollectionResponseDto } from '@/apis/rest/generated/types'
import { StyledDivider } from '@/components/common/divider'
import { ScrollContainer } from '@/components/domains/etc'
import { HomeSectionTitle } from '@/components/domains/home'
import { Anchor } from '@/components/domains/home/Anchor'
import { HomeSectionConstant } from '@/components/domains/home/new/home-section-constant'
import { NormalTypeProductList } from '@/components/domains/home/new/normal-type-product-list'
import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import ROUTES from '@/constants/legacy/constRoutes'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { useIntersectionObserver } from '@/containers/hooks'
import { Base64ConverterUtil } from '@/utils/base64-converter.util'

interface HomeDisplayCollectionNormalTypeListProps {
  homeSectionItem: HomeSectionDisplayCollectionResponseDto
  refCallback: (node: HTMLDivElement) => void
}

export const HomeDisplayCollectionNormalTypeList = ({
  homeSectionItem,
  refCallback,
}: HomeDisplayCollectionNormalTypeListProps) => {
  if (!homeSectionItem.displayCollection) {
    return null
  }

  if (!homeSectionItem.products || homeSectionItem.products.length < HomeSectionConstant.MinItemCount) {
    return null
  }

  const { color } = useTheme()
  const tracker = useTracker()
  const { displayOrder, displayCollection, products } = homeSectionItem

  const title = displayCollection.name

  const { elementRef } = useIntersectionObserver(
    () => {
      tracker.triggerCustomEvent(FitpetMallEventEnum.ViewHomeSection, { sectionName: title })
    },
    { rootMargin: '0px' }
  )

  const seeMoreLink = {
    pathname: ROUTES.SECTION_DETAIL,
    query: {
      displayCollectionId: Base64ConverterUtil.convertNumberIdToBase64ByKey({
        key: 'DisplayCollectionType',
        id: displayCollection?.id,
      }),
      title,
    },
  }

  return (
    <>
      <Anchor id={title?.replace(/[\r\n]+/g, '') || ''} refCallback={refCallback} />
      <StyledContainer ref={elementRef}>
        <HomeSectionTitle sectionIndex={displayOrder} seeMoreLink={seeMoreLink} showSeeMoreButton={products.length > 1}>
          {title}
        </HomeSectionTitle>
        <ScrollContainer>
          <NormalTypeProductList sectionIndex={displayOrder} list={products} sectionName={title} />
        </ScrollContainer>
      </StyledContainer>
      <StyledDivider height="0.1rem" backgroundColor={color.gray70} />
    </>
  )
}

const StyledContainer = styled.div`
  position: relative;
  margin-bottom: 2.4rem;
`
