import styled, { useTheme } from 'styled-components'
import { HomeSectionDisplayCollectionResponseDto } from '@/apis/rest/generated/types'
import { StyledDivider } from '@/components/common/divider'
import { SeoHeading } from '@/components/common/typeface/text.style'
import { Typo, TypoVariant } from '@/components/common/typography'
import { ScrollContainer } from '@/components/domains/etc'
import { HomeButtonSeeMore } from '@/components/domains/home'
import { Anchor } from '@/components/domains/home/Anchor'
import { THUMBNAIL_SIZES, THUMBNAIL_STATES } from '@/components/domains/products/new-product-card/new-product-card.type'
import { VerticalProductCard } from '@/components/domains/products/new-product-card/vertical-product-card'
import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { S3_HOST_URL } from '@/constants/legacy/constEnv'
import ROUTES from '@/constants/legacy/constRoutes'
import { QueryStringKeyEnum } from '@/constants/query-string-key.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { useIntersectionObserver } from '@/containers/hooks'
import { ProductTypeDefinedByFrontend } from '@/types/product-type-defined-by.frontend'
import { Base64ConverterUtil } from '@/utils/base64-converter.util'
import { transformProductTypeDefinedByFrontend } from '@/utils/product/product-card.util'
import { removeScrollbar, textEllipsis } from '@/utils/utilCSS'
import { currencyText } from '@/utils/utilNumber'
import useCustomRouter from '../../../../containers/hooks/useCustomRouter'
import Text from '../../../common/typeface/Text'

const minLength = 1
const maxLength = 20

interface HomeDisplayCollectionMagazineBackgroundImageProps {
  homeSectionItem: HomeSectionDisplayCollectionResponseDto
  refCallback: (node: HTMLDivElement) => void
}

export const HomeDisplayCollectionMagazineBackgroundImage = ({
  homeSectionItem,
  refCallback,
}: HomeDisplayCollectionMagazineBackgroundImageProps) => {
  if (!homeSectionItem.displayCollection) {
    return null
  }

  if (
    !homeSectionItem.products ||
    homeSectionItem.products.length === 0 ||
    homeSectionItem.products.length < minLength
  ) {
    return null
  }
  const { push } = useCustomRouter()

  const { color } = useTheme()

  const tracker = useTracker()
  const { displayCollection, products } = homeSectionItem

  const title = displayCollection.name

  const { elementRef } = useIntersectionObserver(
    () => {
      tracker.triggerCustomEvent(FitpetMallEventEnum.ViewHomeSection, { sectionName: title })
    },
    { rootMargin: '0px' }
  )

  const seeMoreLink = {
    pathname: ROUTES.SECTION_DETAIL,
    query: {
      displayCollectionId: Base64ConverterUtil.convertNumberIdToBase64ByKey({
        key: 'DisplayCollectionType',
        id: displayCollection?.id,
      }),
      title,
    },
  }

  const handleClickProductCard = (product: ProductTypeDefinedByFrontend) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickHomeMagazineBackgroundImageProduct, {
      productId: product.id,
      productName: product.name,
      productPurchasePrice: product.salePrice,
    })
    push({
      pathname: ROUTES.PRODUCTS.DETAIL,
      query: {
        productId: product.id,
        promotion: Base64ConverterUtil.convertNumberIdToBase64ByKey({
          key: 'ProductPromotionType',
          id: product.promotion?.id,
        }),
        ...(title && { [QueryStringKeyEnum.SectionName]: title }),
      },
    })
  }

  return (
    <>
      <StyledContainer backgroundImage={displayCollection.backgroundImage} ref={elementRef}>
        <Anchor id={title} refCallback={refCallback} />
        <StyledTextArea>
          <StyledSeoTitle>
            <Typo variant={TypoVariant.Heading5} color={color.grayWhite}>
              {title}
            </Typo>
          </StyledSeoTitle>
          <StyledDescription>{displayCollection.description}</StyledDescription>
        </StyledTextArea>
        <ScrollContainer>
          <StyledProductCardList>
            {products?.slice(0, maxLength).map((product) => {
              const productData = transformProductTypeDefinedByFrontend(product)
              return (
                <div key={productData.id} style={{ position: 'relative' }}>
                  <VerticalProductCard size={THUMBNAIL_SIZES.lg} onClick={() => handleClickProductCard(productData)}>
                    <VerticalProductCard.Thumbnail
                      productId={productData.id}
                      productName={productData.name}
                      productPromotionScheme={productData.promotion}
                      thumbnailState={THUMBNAIL_STATES.basicLabel}
                      imageUrl={productData.thumbnail}
                      isLiked={productData.isLiked}
                      isRealSoldOut={productData.isRealSoldOut}
                      isNotAvailable={productData.isNotAvailable}
                      trackingData={productData.thumbnailData}
                      sticker={productData.sticker}
                    />
                    <StyledProductName>{productData.name}</StyledProductName>
                    <Typo variant={TypoVariant.Body1Bold} color={color.grayWhite} style={{ marginTop: '0.4rem' }}>
                      {currencyText(productData.salePrice)}
                    </Typo>
                  </VerticalProductCard>
                </div>
              )
            })}
          </StyledProductCardList>
        </ScrollContainer>
        <HomeButtonSeeMore className={'btn-see-all'} seeMoreLink={seeMoreLink} isWhiteFont />
      </StyledContainer>
      <StyledDivider height="0.1rem" backgroundColor={color.gray70} />
    </>
  )
}

const StyledContainer = styled.div<{ backgroundImage: string | undefined }>`
  overflow-x: hidden;
  width: 100%;
  object-fit: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
    url(${(props) => `${S3_HOST_URL}/${props.backgroundImage}`});
  background-color: ${(props) => props.theme.color.gray100}; // 스켈레톤 백그라운드
  background-repeat: no-repeat;
  background-size: cover;

  .btn-see-all {
    border: 1px solid ${(props) => props.theme.color.grayWhite};

    .btn-text {
      color: ${(props) => props.theme.color.grayWhite};
    }

    i {
      color: ${(props) => props.theme.color.grayWhite} !important;
    }
  }
`

const StyledTextArea = styled.div`
  height: 12.8rem; // Text Area 고정값 적용
`

const StyledSeoTitle = styled.h2`
  ${SeoHeading.h3};
  margin-top: 3.8rem;
  margin-left: 1.8rem;
  white-space: pre;
  color: ${(props) => props.theme.color.grayWhite};
`

const StyledDescription = styled(Text.Body3)`
  opacity: 0.7;
  margin-top: 0.8rem;
  margin-left: 1.8rem;
  white-space: pre;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.color.grayWhite};
`

const StyledProductCardList = styled.div`
  ${removeScrollbar};
  display: flex;
  column-gap: 0.8rem;

  & > *:first-child {
    margin-left: 1.6rem;
  }

  & > *:last-child {
    padding-right: 1.6rem;
  }
`

const StyledProductName = styled.div`
  pointer-events: none;
  margin-top: 0.8rem;
  color: ${(props) => props.theme.color.grayWhite};
  font-size: ${(props) => props.theme.fontSize.body3};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: 1.8rem;
  letter-spacing: ${({ theme }) => theme.letterSpacing.font};
  ${textEllipsis(2)};
`
