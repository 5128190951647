import dynamic from 'next/dynamic'
import styled from 'styled-components'
import { LinkButton } from '@/components/common/buttons'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { CartItemCountCircle } from '@/components/domains/etc'
import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { IconSizeEnum } from '@/constants/icon-size.enum'
import { FIXED_HEIGHT, SCREEN_MEDIA_QUERY } from '@/constants/legacy/constLayout'
import ROUTES from '@/constants/legacy/constRoutes'
import { QueryStringKeyEnum } from '@/constants/query-string-key.enum'
import { ServiceEnum } from '@/constants/service.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { usePetType } from '@/containers/hooks'

const NoSSRPetTypeSelectButton = dynamic(() => import('@/components/domains/layouts/header/pet-type-select-button'), {
  ssr: false,
})

export const HomeHeader = () => {
  const { getPetType } = usePetType()
  const tracker = useTracker()

  return (
    <StyledHeaderContainer>
      <NoSSRPetTypeSelectButton />
      <StyledSearchInputWrapper>
        <LinkButton
          rel="nosublink"
          url={{ pathname: ROUTES.SEARCH, query: { [QueryStringKeyEnum.SearchPetType]: getPetType() } }}
          onClick={() => {
            tracker.triggerCustomEvent(FitpetMallEventEnum.ClickGnbSearchIcon)
          }}
        >
          <StyledSearchInput data-cy="search-input">
            <span className={'search-placeholder'}>검색어를 입력해 주세요!</span>
            <FlatIcon type={IconNameEnum.IcSearch} size={IconSizeEnum.Size24} />
          </StyledSearchInput>
        </LinkButton>
      </StyledSearchInputWrapper>
      <LinkButton
        rel="nosublink"
        url={ROUTES.CART}
        onClick={() => {
          tracker.triggerCustomEvent(FitpetMallEventEnum.ClickGnbCartIcon, { service: ServiceEnum.Fitpetmall })
        }}
        style={{ display: 'flex', alignItems: 'center', position: 'relative' }}
      >
        <CartItemCountCircle />
        <FlatIcon type={IconNameEnum.IcCart} size={IconSizeEnum.Size24} />
      </LinkButton>
    </StyledHeaderContainer>
  )
}

const StyledHeaderContainer = styled.div`
  background-color: ${({
    theme: {
      color: { grayWhite },
    },
  }) => grayWhite};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${FIXED_HEIGHT.HEADER / 10}rem;
  border-bottom: 0.1rem solid ${GlobalColorEnum.Gray50};
  padding: 0 1.6rem;
`

const StyledSearchInputWrapper = styled.div`
  flex: 1;
`

const StyledSearchInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  margin-right: 1.6rem;
  height: 3.6rem;
  background: transparent;
  border: 1px solid ${GlobalColorEnum.Gray200};
  border-radius: 8px;

  @media ${SCREEN_MEDIA_QUERY.xs} {
    justify-content: flex-start;
  }
  span.search-placeholder {
    font-size: 1.3rem;
    color: ${(props) => props.theme.color.gray200};
    @media ${SCREEN_MEDIA_QUERY.xs} {
      display: none;
    }
  }
`
