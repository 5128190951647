import { useEffect, useMemo, useRef, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { FIXED_HEIGHT, SCREEN_MEDIA_QUERY, SCREEN_SIZES, Z_INDEXES } from '@/constants/legacy/constLayout'
import ROUTES from '@/constants/legacy/constRoutes'
import { useCustomRouter } from '@/containers/hooks'
import { isInApp, throttle } from '@/utils/utilCommon'

export const HomeSectionFloatingButton = () => {
  const { iconSize, color } = useTheme()
  const { pathname } = useCustomRouter()
  const [isApp, isAppToggle] = useState<boolean>(true)
  const [isShowTopButton, setIsShowTopButton] = useState<boolean>(false)
  const lastScrollRef = useRef<number>(0)
  const scrollRef = useRef<HTMLDivElement>(null)

  // 스크롤 위치에 따라 스크롤탑, 플로팅 버튼 분기
  const onScroll = useMemo(
    () =>
      throttle(() => {
        const { pageYOffset } = window
        setIsShowTopButton(pageYOffset > 100 && lastScrollRef.current > pageYOffset)
        lastScrollRef.current = pageYOffset
      }, 100),
    [isShowTopButton]
  )

  const getBottom = () => {
    if (
      pathname === ROUTES.EVENTS_100_DEAL ||
      pathname === ROUTES.PRODUCTS.DETAIL ||
      pathname === '/products-new/[productId]'
    ) {
      return FIXED_HEIGHT.PRODUCT_ORDER_BUTTON + 16
    }
    if (isApp) {
      return 16
    }
    return FIXED_HEIGHT.BOTTOM_TAB_NAVIGATOR + 16
  }

  useEffect(() => {
    if (isAppToggle) {
      isAppToggle(isInApp())
    }
  }, [isInApp])

  useEffect(() => {
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <StyledFixedContainer bottom={getBottom()} ref={scrollRef}>
      <StyledButton show={isShowTopButton} onClick={() => window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })}>
        <FlatIcon type={IconNameEnum.IcTop} size={iconSize.size16} color={color.gray700} />
      </StyledButton>
    </StyledFixedContainer>
  )
}

const StyledFixedContainer = styled.div<{ bottom: number }>`
  z-index: ${Z_INDEXES.SCROLL_TOP_BUTTON};
  position: fixed;
  bottom: ${({ bottom }) => `calc(env(safe-area-inset-bottom) + ${bottom}px)`};

  // pc 기본
  right: calc(50vw - 576px + 4rem + 20px);
  @media (max-width: ${SCREEN_SIZES.lg}px) {
    right: calc(50vw - 288px + 20px);
  }
  @media ${SCREEN_MEDIA_QUERY.xs} {
    right: 20px;
  }
  display: flex;
  gap: 0.8rem;
  align-items: flex-end;
  flex-direction: column;
  pointer-events: none;

  div {
    pointer-events: auto;
  }
`

const StyledButton = styled.div<{ show: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border: 1px solid ${GlobalColorEnum.Gray100};
  border-radius: 50%;
  background-color: ${(props) => props.theme.color.grayWhite};
  pointer-events: auto;
  box-shadow: 0.2rem 0.4rem 0.4rem rgba(0, 0, 0, 0.04);
  cursor: pointer;
  opacity: 1;

  @keyframes scaleInButton {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes hideButton {
    100% {
      opacity: 0;
    }
  }

  // 사라질땐 opacity가 0이 되면서 사라지고 다시 나타날땐 opacity가 1인 상태로 scale이 커져야 하기 떄문에 원복용으로 생성한 애니메이션
  @keyframes restoreAfterHide {
    100% {
      opacity: 1;
      transform: scale(0);
    }
  }

  animation: ${({ show }) =>
    show
      ? 'scaleInButton 0.3s cubic-bezier(0.65, 0, 0.35, 1) forwards'
      : 'hideButton 0.3s cubic-bezier(0.65, 0, 0.35, 1) forwards, 0s scaleOutButton 0.4s backwards'};
`
