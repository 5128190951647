import styled from 'styled-components'

const StyledDivider = styled.div<{ height: string; backgroundColor: string; margin?: string }>`
  width: 100%;
  height: ${({ height }) => height};
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin: ${({ margin }) => margin};
`

export { StyledDivider }
