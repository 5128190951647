import { HomeSectionBannerResponseDto } from '@/apis/rest/generated/types'
import { ImageBannerSlider } from '@/components/domains/home/new/image-banner-slider'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'

interface HomeMiddleBannerProps {
  homeSectionItem: HomeSectionBannerResponseDto
}

export const HomeBannerMiddle = ({ homeSectionItem }: HomeMiddleBannerProps) => {
  const eventTrackerName = {
    view: FitpetMallEventEnum.ViewHomeBanner,
    click: FitpetMallEventEnum.ClickHomeBanner,
  }

  return (
    <ImageBannerSlider
      banners={homeSectionItem.imageTypeBanners}
      eventTrackerName={eventTrackerName}
      bannerStyles={{
        height: 112,
        logoWidth: 68,
      }}
    />
  )
}
