import { Children, FC, forwardRef, PropsWithChildren, ReactNode, useEffect, useRef } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import { ButtonSlideScrollLeft, ButtonSlideScrollRight } from '@/components/common/buttons'
import { Z_INDEXES } from '@/constants/legacy/constLayout'

const SLIDER_SETTINGS = {
  speed: 500,
  touchThreshold: 100,
  autoplaySpeed: 5000,
  easing: 'linear',
  infinite: true,
}

type CarouselBaseProps = {
  disableBodyScrollWhileSliding?: boolean
  autoplay?: boolean
  centerMode?: boolean
  lazyLoad?: boolean
  adaptiveHeight?: boolean
  initialSlide?: number
  centerPadding?: string
  className?: string
  slidesToShow?: number
  dots?: boolean
  responsive?: {
    breakpoint: number
    settings: {
      centerPadding: string
    }
  }[]
  onIndexChange?: (currentIndex: number) => void
  onSwipe?: (actionType: 'left' | 'right') => void
  arrowTopPosition?: number
  NextArrow?: ReactNode
  PreviousArrow?: ReactNode
}

export const CarouselBase: FC<PropsWithChildren<CarouselBaseProps>> = forwardRef(
  (
    {
      onIndexChange,
      arrowTopPosition = 130,
      initialSlide = 0,
      adaptiveHeight = false,
      disableBodyScrollWhileSliding = false,
      NextArrow,
      PreviousArrow,
      children,
      ...sliderProps
    },
    ref
  ) => {
    const carouselRef = useRef<Slider | null>(null)

    useEffect(() => {
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
          carouselRef.current?.slickPlay()
        }
      }
      document.addEventListener('visibilitychange', handleVisibilityChange)
    }, [])

    return (
      <div>
        <StyledWrapper>
          {/* @ts-ignore */}
          <Slider
            ref={(node) => {
              carouselRef.current = node
              if (typeof ref === 'function') {
                ref(node)
              } else if (ref) {
                ref.current = node
              }
            }}
            nextArrow={NextArrow || <RenderNextArrow topPosition={arrowTopPosition} />}
            prevArrow={PreviousArrow || <RenderPrevArrow topPosition={arrowTopPosition} />}
            afterChange={(currentSlide: number) => {
              onIndexChange?.(currentSlide)
            }}
            initialSlide={initialSlide}
            adaptiveHeight={adaptiveHeight}
            {...SLIDER_SETTINGS}
            {...sliderProps}
          >
            {Children.map(children, (child) => {
              /**
               * 캐러셀 드래그 시 클릭 이벤트 막기 제거 관련 내용
               * <div onClickCapture={handleOnItemClick}>{child}</div>
               * slick 클릭 막기 시 슬라이드 내 클릭 요소 이슈로 인해 slick 옵션 중 touchThreshold 값을 높여주어 클릭 이슈를 개선하도록 함.
               * @see {@link https://lily-im.tistory.com/109}
               */
              return <div>{child}</div>
            })}
          </Slider>
        </StyledWrapper>
      </div>
    )
  }
)

const StyledWrapper = styled.div`
  .slick-dots {
    bottom: 20px;
  }

  .slick-dots li {
    margin: 0px;
    width: 15px;
    height: 15px;
  }

  .slick-dots li button {
    width: 15px;
    height: 15px;
  }

  .slick-dots li button:before {
    font-size: 30px;
  }

  .slick-slide > div {
    display: flex;
  }
`

const RenderNextArrow = (props: any) => {
  const { onClick, topPosition } = props
  return (
    <NextArrowContainer top={topPosition}>
      <ButtonSlideScrollRight visible onClick={onClick} />
    </NextArrowContainer>
  )
}

const RenderPrevArrow = (props: any) => {
  const { onClick, topPosition } = props
  return (
    <PrevArrowContainer top={topPosition}>
      <ButtonSlideScrollLeft visible onClick={onClick} />
    </PrevArrowContainer>
  )
}

const NextArrowContainer = styled.div<{ top: number }>`
  position: absolute;
  right: 0;
  top: ${({ top }) => `${top}px`};
  z-index: ${Z_INDEXES.CAROUSEL_ARROW_BUTTON};
`

const PrevArrowContainer = styled.div<{ top: number }>`
  position: absolute;
  left: 0;
  top: ${({ top }) => `${top}px`};
  z-index: ${Z_INDEXES.CAROUSEL_ARROW_BUTTON};
`
CarouselBase.displayName = 'CarouselBase'
