import { ForwardedRef, useEffect } from 'react'
import { throttle } from '@/utils/utilCommon'

export const useExecuteOnScrollTop = ({
  callback,
  ref,
  enabled = true,
}: {
  callback?: () => void
  ref: ForwardedRef<HTMLDivElement>
  enabled?: boolean
}) => {
  const headerHeight = document.getElementById('header')?.offsetHeight || 0

  const wait = 300

  const handleScroll = throttle(() => {
    if (typeof ref === 'function') return
    const target = ref?.current?.getBoundingClientRect().top || 0

    if (target && target - headerHeight < 0) {
      callback?.()
    }
  }, wait)

  useEffect(() => {
    if (!enabled) {
      window.removeEventListener('scroll', handleScroll) //clean up
    } else {
      window.addEventListener('scroll', handleScroll)
    }
    return () => {
      window.removeEventListener('scroll', handleScroll) //clean up
    }
  }, [enabled])
}
