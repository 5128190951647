import { UrlObject } from 'url'
import { FC, PropsWithChildren, ReactNode, useMemo } from 'react'
import styled, { useTheme } from 'styled-components'
import { LinkButton } from '@/components/common/buttons'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { Typo, TypoVariant } from '@/components/common/typography'
import { HomeSectionTitleEnum } from '@/constants/home-section-title.enum'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { useHomeTracker } from '@/containers/event-tracker/home.event-tracker'

type HomeSectionTitleProps = {
  children: HomeSectionTitleEnum | ReactNode
  seeMoreLink?: string | UrlObject
  showSeeMoreButton?: boolean
  sectionIndex?: number
  hasMarginBottom?: boolean
  onClickSeeMore?: () => void
}

/**
 * 홈섹션 타이틀 컴포넌트
 * 타이틀 옆 더보기 버튼은 상품 갯수가 19개 이상일 시에만 나와야 한다
 * @param {HomeSectionTitleEnum | ReactNode} children - 타이틀로 쓰일 텍스트 or 엘리먼트
 * @param {string | UrlObject} seeMoreLink - 타이틀 옆 더보기 링크 클릭 시 이동할 링크
 * @param {boolean | undefined} showSeeMoreButton - 더보기 버튼이 나와야 하는지에 대한 flag, 리스트 갯수가 19개 이상일 시에만 true로 내려주어야 한다
 */
const HomeSectionTitle: FC<PropsWithChildren<HomeSectionTitleProps>> = ({
  children,
  seeMoreLink,
  showSeeMoreButton,
  sectionIndex,
  hasMarginBottom = true,
  onClickSeeMore,
}) => {
  const { color } = useTheme()
  const { trackClickHomeSectionMoreEvent } = useHomeTracker()

  const isMultiLine = useMemo(() => {
    // children이 텍스트고 이스케이프 문자로 줄바꿈이 있다면 true
    return typeof children === 'string' && children.split('\n').length > 1
  }, [children])

  /**
   * @desc children이 string이고 이스케이프 문자가 포함되어 있다면 줄바꿈 시키는 함수
   */
  const convertEscapeToNewLine = () => {
    if (typeof children === 'string') {
      return children.split('\n').map((item) => {
        return <div key={item}>{item}</div>
      })
    } else {
      return children
    }
  }

  const handleClickLink = () => {
    if (onClickSeeMore) {
      onClickSeeMore()
    } else {
      trackClickHomeSectionMoreEvent({
        sectionIndex: sectionIndex,
        sectionName: typeof children === 'string' ? children : '',
      })
    }
  }

  return (
    <>
      <StyledContainer data-testid="home-section-title" isMultiLine={isMultiLine} hasMarginBottom={hasMarginBottom}>
        <Typo as="h2" variant={TypoVariant.Heading5}>
          {convertEscapeToNewLine()}
        </Typo>
        {seeMoreLink && showSeeMoreButton && (
          <StyledLinkButton onClick={handleClickLink} url={seeMoreLink}>
            <Typo variant={TypoVariant.Body3Regular} color={color.gray500}>
              더 보기
            </Typo>
            <FlatIcon type={IconNameEnum.IcArrowRight} size={'1.6rem'} color={color.gray500} />
          </StyledLinkButton>
        )}
      </StyledContainer>
    </>
  )
}

const StyledContainer = styled.div<{ isMultiLine: boolean; hasMarginBottom: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  // 타이틀에 이스케이프 문자로 줄바꿈이 있다면 align을 아래로, 줄바꿈이 없다면 중앙 정렬을 한다
  align-items: ${({ isMultiLine }) => (isMultiLine ? 'flex-end' : 'center')};
  position: relative;
  margin-top: 2.4rem;
  margin-bottom: ${({ hasMarginBottom }) => hasMarginBottom && '1.6rem'};
  padding: 0 1.6rem;
`

const StyledLinkButton = styled(LinkButton)`
  display: flex;
  justify-content: center;
  align-items: center;
`

export default HomeSectionTitle
