import { FC, memo } from 'react'
import styled from 'styled-components'
import { Image } from '@/components/common/images/Image'
import { CardLayoutEnum } from '@/constants/card-layout.enum'
import { ProductThumbnailSizeEnum } from '@/constants/product-thumbnail-size.enum'
import { getThumbnailPlaceHolder } from '@/utils/utilURL'

type SkeletonProductImageProps = {
  imageSize: ProductThumbnailSizeEnum
  skeletonType: CardLayoutEnum
}

const SkeletonProductImage: FC<SkeletonProductImageProps> = ({
  imageSize = ProductThumbnailSizeEnum.Card136,
  skeletonType = CardLayoutEnum.Vertical,
}) => {
  return (
    <ImageContainer size={imageSize} type={skeletonType}>
      <Image src={getThumbnailPlaceHolder(imageSize)} alt="SkeletonUI" />
    </ImageContainer>
  )
}

export default memo(SkeletonProductImage)

const ImageContainer = styled.div<{ size: ProductThumbnailSizeEnum; type: CardLayoutEnum }>`
  position: relative;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  padding-top: ${({ size }) => (size === ProductThumbnailSizeEnum.Fill ? '100%' : 'initial')};

  object-fit: cover;
  max-height: 326px;
  max-width: 326px;

  overflow: hidden;

  border-radius: 6px;

  margin-right: ${({ type, theme }) => (type === CardLayoutEnum.Horizontal ? theme.spacing.size16 : 0)};
  margin-bottom: ${({ type, theme }) => (type === CardLayoutEnum.Vertical ? theme.spacing.size8 : 0)};
`
