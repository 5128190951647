import { match } from 'ts-pattern'
import { HomeSectionBannerResponseDto, HomeSectionBannerTypeEnum } from '@/apis/rest/generated/types'
import { HomeBannerBottom } from '@/components/domains/home/new/home-banner-bottom'
import { HomeBannerMiddle } from '@/components/domains/home/new/home-banner-middle'
import { HomeBannerMultiLine } from '@/components/domains/home/new/home-banner-multi-line'
import { HomeBannerReview } from '@/components/domains/home/new/home-banner-review'
import { HomeBannerTopRoll } from '@/components/domains/home/new/home-banner-top-roll'

export const HomeSectionBanners = ({ homeSectionItem }: { homeSectionItem: HomeSectionBannerResponseDto }) => {
  return (
    <>
      {match(homeSectionItem.bannerType)
        .with(HomeSectionBannerTypeEnum.BANNER_TOP_SLIM, () => <></>)
        .with(HomeSectionBannerTypeEnum.BANNER_SLIM, () => <></>)
        .with(HomeSectionBannerTypeEnum.BANNER_TOP_ROLL, () => (
          <HomeBannerTopRoll key={homeSectionItem.id} homeSectionItem={homeSectionItem} />
        ))
        .with(HomeSectionBannerTypeEnum.BANNER_HOME_MIDDLE, () => (
          <HomeBannerMiddle key={homeSectionItem.id} homeSectionItem={homeSectionItem} />
        ))
        .with(HomeSectionBannerTypeEnum.BANNER_HOME_BOTTOM, () => (
          <HomeBannerBottom key={homeSectionItem.id} homeSectionItem={homeSectionItem} />
        ))
        .with(HomeSectionBannerTypeEnum.BANNER_PRODUCT_TOP, () => <></>)
        .with(HomeSectionBannerTypeEnum.BANNER_REVIEW, () => <HomeBannerReview key={homeSectionItem.id} />)
        .with(HomeSectionBannerTypeEnum.BANNER_HOME_MULTI_LINE, () => (
          <HomeBannerMultiLine key={homeSectionItem.id} homeSectionItem={homeSectionItem} />
        ))
        .exhaustive()}
    </>
  )
}
